import React, { useContext } from "react";
import BreadCrumb from "./BreadCrumb";
import about1 from "../assets/image/about2.avif";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Pagination } from "swiper/modules";
import { LanguageContext } from "../context/LanguageContext";
import translations from "../data/langData";
import images from "../data/images";
import { Helmet } from "react-helmet";
import SeoBreadCrumb from "./SeoBreadCrumb";
const SeoAbout5 = () => {
  const { language } = useContext(LanguageContext);
  const t = translations[language];

  return (
    <>
      <SeoBreadCrumb title={t.seoAbout5} />
      <Helmet>
        <title>{t.seoAbout5}</title>
      </Helmet>
      <div>
        <section className="about__section about__page--section section--padding">
          <div className="container">
            <div className="about__inner d-flex">
              <div className="about__thumbnail position-relative">
                <div className="about__thumbnail--list one position-relative">
                  <img src={about1} alt="about-thumb" loading="lazy" />
                  <div className="rating__star--text">
                    <svg
                      width={34}
                      height={31}
                      viewBox="0 0 34 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 0L22.2959 9.71076L33.168 11.7467L25.569 19.7842L26.9923 30.7533L17 26.01L7.00765 30.7533L8.43098 19.7842L0.832039 11.7467L11.7041 9.71076L17 0Z"
                        fill="#fff"
                      />
                    </svg>
                    <span>{t.star}</span>
                  </div>
                </div>
              </div>
              <div className="about__content">
                <div className="section__heading">
                  <h3 className="section__heading--subtitle h5">
                    <svg
                      width={18}
                      height={18}
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_15_6)">
                        <path
                          d="M9.00021 4.72925L2.5806 10.0215C2.5806 10.029 2.57872 10.04 2.57497 10.055C2.57129 10.0698 2.56934 10.0806 2.56934 10.0883V15.4473C2.56934 15.6408 2.64008 15.8085 2.78152 15.9497C2.92292 16.091 3.09037 16.1621 3.2839 16.1621H7.571V11.8747H10.4295V16.1622H14.7165C14.91 16.1622 15.0777 16.0913 15.2189 15.9497C15.3603 15.8086 15.4313 15.6408 15.4313 15.4473V10.0883C15.4313 10.0586 15.4272 10.0361 15.4201 10.0215L9.00021 4.72925Z"
                          fill="#F23B3B"
                        />
                        <path
                          d="M17.8758 8.81572L15.4309 6.78374V2.2285C15.4309 2.12437 15.3974 2.03872 15.3302 1.9717C15.2636 1.90475 15.178 1.87128 15.0736 1.87128H12.93C12.8258 1.87128 12.7401 1.90475 12.6731 1.9717C12.6062 2.03872 12.5727 2.1244 12.5727 2.2285V4.4056L9.8486 2.12792C9.61069 1.93439 9.3278 1.83765 9.00026 1.83765C8.67275 1.83765 8.3899 1.93439 8.15175 2.12792L0.124063 8.81572C0.0496462 8.87516 0.00885955 8.95517 0.00127316 9.05567C-0.00627412 9.15609 0.0197308 9.2438 0.079366 9.31818L0.771565 10.1444C0.831201 10.2113 0.909254 10.2523 1.00604 10.2673C1.09539 10.2748 1.18475 10.2486 1.27411 10.1891L9.00002 3.74687L16.726 10.1891C16.7857 10.241 16.8637 10.2669 16.9605 10.2669H16.994C17.0907 10.2522 17.1686 10.211 17.2285 10.1442L17.9208 9.31814C17.9803 9.2436 18.0064 9.15605 17.9987 9.05551C17.991 8.95528 17.9501 8.87527 17.8758 8.81572Z"
                          fill="#F23B3B"
                        />
                      </g>
                      <defs>
                        <clipPath>
                          <rect width={18} height={18} fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    {t.kunstoff}
                  </h3>
                  <h2
                    style={{ fontSize: "25px", lineHeight: "35px" }}
                    className="section__heading--title"
                  >
                    {t.kunstoffTitle}
                  </h2>
                  <p className="section__heading--desc">{t.kunstoffDesc}</p>
                </div>
                <div className="about__content--info d-flex">
                  <div className="about__content--info__list d-flex align-items-center">
                    <div className="about__content--info__icon">
                      <img
                        src="assets/img/other/about-info-icon.png"
                        alt="icon"
                        loading="lazy"
                      />
                    </div>
                    <h3 className="about__content--info__title">{t.charac1}</h3>
                  </div>
                  <div className="about__content--info__list d-flex align-items-center">
                    <div className="about__content--info__icon">
                      <img
                        src="assets/img/other/about-info-icon2.png"
                        alt="icon"
                        loading="lazy"
                      />
                    </div>
                    <h3 className="about__content--info__title">{t.charac2}</h3>
                  </div>
                </div>
                <div className="about__content--details d-flex align-items-center">
                  <div className="about__experince">
                    <span className="about__experince--number">30</span>
                    <span className="about__experince--text">{t.year}</span>
                  </div>
                  <div className="living__details--content__wrapper">
                    <p className="living__details--content__list">
                      <svg
                        width={17}
                        height={17}
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.5 0.25C3.95 0.25 0.25 3.95 0.25 8.5C0.25 13.05 3.95 16.75 8.5 16.75C13.05 16.75 16.75 13.05 16.75 8.5C16.75 3.95 13.05 0.25 8.5 0.25ZM8.5 15.25C4.775 15.25 1.75 12.225 1.75 8.5C1.75 4.775 4.775 1.75 8.5 1.75C12.225 1.75 15.25 4.775 15.25 8.5C15.25 12.225 12.225 15.25 8.5 15.25Z"
                          fill="#F23B3B"
                        />
                        <path
                          d="M11.625 5.97505L7.525 9.87505L5.4 7.75005C5.1 7.45005 4.625 7.45005 4.35 7.75005C4.05 8.05005 4.05 8.52505 4.35 8.80005L7 11.45C7.15 11.6 7.35 11.675 7.525 11.675C7.7 11.675 7.9 11.6 8.05 11.475L12.675 7.07505C12.975 6.80005 12.975 6.32505 12.7 6.02505C12.4 5.70005 11.925 5.70005 11.625 5.97505Z"
                          fill="#F23B3B"
                        />
                      </svg>
                      {t.charac3}
                    </p>
                    <p className="living__details--content__list">
                      <svg
                        width={17}
                        height={17}
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.5 0.25C3.95 0.25 0.25 3.95 0.25 8.5C0.25 13.05 3.95 16.75 8.5 16.75C13.05 16.75 16.75 13.05 16.75 8.5C16.75 3.95 13.05 0.25 8.5 0.25ZM8.5 15.25C4.775 15.25 1.75 12.225 1.75 8.5C1.75 4.775 4.775 1.75 8.5 1.75C12.225 1.75 15.25 4.775 15.25 8.5C15.25 12.225 12.225 15.25 8.5 15.25Z"
                          fill="#F23B3B"
                        />
                        <path
                          d="M11.625 5.97505L7.525 9.87505L5.4 7.75005C5.1 7.45005 4.625 7.45005 4.35 7.75005C4.05 8.05005 4.05 8.52505 4.35 8.80005L7 11.45C7.15 11.6 7.35 11.675 7.525 11.675C7.7 11.675 7.9 11.6 8.05 11.475L12.675 7.07505C12.975 6.80005 12.975 6.32505 12.7 6.02505C12.4 5.70005 11.925 5.70005 11.625 5.97505Z"
                          fill="#F23B3B"
                        />
                      </svg>
                      {t.charac4}
                    </p>
                    <p className="living__details--content__list">
                      <svg
                        width={17}
                        height={17}
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.5 0.25C3.95 0.25 0.25 3.95 0.25 8.5C0.25 13.05 3.95 16.75 8.5 16.75C13.05 16.75 16.75 13.05 16.75 8.5C16.75 3.95 13.05 0.25 8.5 0.25ZM8.5 15.25C4.775 15.25 1.75 12.225 1.75 8.5C1.75 4.775 4.775 1.75 8.5 1.75C12.225 1.75 15.25 4.775 15.25 8.5C15.25 12.225 12.225 15.25 8.5 15.25Z"
                          fill="#F23B3B"
                        />
                        <path
                          d="M11.625 5.97505L7.525 9.87505L5.4 7.75005C5.1 7.45005 4.625 7.45005 4.35 7.75005C4.05 8.05005 4.05 8.52505 4.35 8.80005L7 11.45C7.15 11.6 7.35 11.675 7.525 11.675C7.7 11.675 7.9 11.6 8.05 11.475L12.675 7.07505C12.975 6.80005 12.975 6.32505 12.7 6.02505C12.4 5.70005 11.925 5.70005 11.625 5.97505Z"
                          fill="#F23B3B"
                        />
                      </svg>
                      {t.charac5}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="popular__featured--section section--padding">
          <div className="container-fluid p-0">
            <div className="section__heading text-center mb-50">
              <h3 className="section__heading--subtitle color__white h5">
                <svg
                  className="me-2 mb-2"
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_15_6)">
                    <path
                      d="M9.00021 4.72925L2.5806 10.0215C2.5806 10.029 2.57872 10.04 2.57497 10.055C2.57129 10.0698 2.56934 10.0806 2.56934 10.0883V15.4473C2.56934 15.6408 2.64008 15.8085 2.78152 15.9497C2.92292 16.091 3.09037 16.1621 3.2839 16.1621H7.571V11.8747H10.4295V16.1622H14.7165C14.91 16.1622 15.0777 16.0913 15.2189 15.9497C15.3603 15.8086 15.4313 15.6408 15.4313 15.4473V10.0883C15.4313 10.0586 15.4272 10.0361 15.4201 10.0215L9.00021 4.72925Z"
                      fill="#F23B3B"
                    />
                    <path
                      d="M17.8758 8.81572L15.4309 6.78374V2.2285C15.4309 2.12437 15.3974 2.03872 15.3302 1.9717C15.2636 1.90475 15.178 1.87128 15.0736 1.87128H12.93C12.8258 1.87128 12.7401 1.90475 12.6731 1.9717C12.6062 2.03872 12.5727 2.1244 12.5727 2.2285V4.4056L9.8486 2.12792C9.61069 1.93439 9.3278 1.83765 9.00026 1.83765C8.67275 1.83765 8.3899 1.93439 8.15175 2.12792L0.124063 8.81572C0.0496462 8.87516 0.00885955 8.95517 0.00127316 9.05567C-0.00627412 9.15609 0.0197308 9.2438 0.079366 9.31818L0.771565 10.1444C0.831201 10.2113 0.909254 10.2523 1.00604 10.2673C1.09539 10.2748 1.18475 10.2486 1.27411 10.1891L9.00002 3.74687L16.726 10.1891C16.7857 10.241 16.8637 10.2669 16.9605 10.2669H16.994C17.0907 10.2522 17.1686 10.211 17.2285 10.1442L17.9208 9.31814C17.9803 9.2436 18.0064 9.15605 17.9987 9.05551C17.991 8.95528 17.9501 8.87527 17.8758 8.81572Z"
                      fill="#F23B3B"
                    />
                  </g>
                  <defs>
                    <clipPath>
                      <rect width={18} height={18} fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {t.kunstoff}
              </h3>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-9 ">
                    <h3 className="section__heading--title color__white">
                      {t.kunstoffDesc2}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid my-4">
              <Swiper
                slidesPerView={1}
                spaceBetween={3}
                pagination={{
                  clickable: true,
                }}
                loop={true}
                autoplay={{
                  // Enable autoplay
                  delay: 1500, // Delay between slides (in milliseconds)
                  disableOnInteraction: false, // Keep autoplay running after interaction
                }}
                speed={2000}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 23,
                  },
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
              >
                {images.map((item, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={item.img}
                      alt={`Slide ${index + 1}`}
                      className="img-fluid"
                      style={{
                        width: "100%",
                        height: "370px",
                        objectFit: "cover",
                      }}
                      loading="lazy"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
        <section className="building__amenities--section section--padding">
          <div className="container">
            <div className="section__heading text-center mb-50">
              <h2 className="section__heading--title">{t.aboutHome}</h2>
            </div>
            <div className="building__amenities--inner d-flex">
              <div className="amenities__box">
                <div className="amenities__icone text-right">
                  <span>
                    <svg
                      width={30}
                      height={34}
                      viewBox="0 0 30 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.9241 5.849C24.1438 4.52979 19.5648 2.59614 15.3077 0.098999C15.1979 0.0342357 15.0719 0 14.9436 0C14.8152 0 14.6893 0.0342357 14.5795 0.098999C10.3309 2.61282 5.74914 4.54746 0.962046 5.849C0.809441 5.89179 0.675396 5.98212 0.580388 6.10617C0.48538 6.23022 0.434632 6.38119 0.435891 6.53601V13.816C0.39353 16.6537 0.937333 19.4707 2.03491 22.0993C3.13249 24.7278 4.76139 27.1141 6.82461 29.116C9.96204 32.176 13.6164 33.869 14.9436 33.869C16.2708 33.869 19.9251 32.176 23.0626 29.116C25.1254 27.1139 26.754 24.7275 27.8514 22.099C28.9488 19.4705 29.4925 16.6536 29.4502 13.816V6.53601C29.4515 6.38119 29.4008 6.23022 29.3058 6.10617C29.2107 5.98212 29.0767 5.89179 28.9241 5.849ZM28.0195 13.816C28.0603 16.4651 27.5537 19.0952 26.5299 21.5493C25.5061 24.0035 23.9862 26.2317 22.0605 28.101C18.9754 31.11 15.6769 32.447 14.9436 32.447C14.2102 32.447 10.9118 31.11 7.82564 28.101C5.90036 26.2315 4.38068 24.0032 3.35711 21.5491C2.33353 19.0949 1.827 16.465 1.86769 13.816V7.077C6.45127 5.7833 10.8458 3.92178 14.9436 1.53799C19.0499 3.90571 23.4422 5.76632 28.0195 7.077V13.816Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8.60038 6.99693C8.56698 6.91137 8.51655 6.83309 8.452 6.76661C8.38744 6.70014 8.31003 6.64678 8.22423 6.6096C8.13843 6.57242 8.04593 6.55218 7.95207 6.55C7.8582 6.54782 7.76482 6.56378 7.67731 6.59694C6.39833 7.09694 5.08654 7.55992 3.77987 7.96892C3.6333 8.01604 3.50581 8.10724 3.4157 8.22941C3.32559 8.35158 3.2775 8.49846 3.27833 8.64894V11.4009C3.27833 11.5866 3.35397 11.7646 3.48861 11.8959C3.62325 12.0272 3.80587 12.1009 3.99628 12.1009C4.18669 12.1009 4.36931 12.0272 4.50395 11.8959C4.63859 11.7646 4.71423 11.5866 4.71423 11.4009V9.17091C5.88859 8.79291 7.06192 8.37093 8.20859 7.92393C8.38518 7.84998 8.52507 7.71154 8.5983 7.53828C8.67153 7.36502 8.67227 7.17072 8.60038 6.99693Z"
                        fill="currentColor"
                      />
                      <path
                        d="M9.9588 7.13242C10.0578 7.13256 10.1557 7.1121 10.246 7.07243H10.2593C10.4341 6.99388 10.5703 6.85167 10.6388 6.67631C10.7072 6.50094 10.7025 6.30641 10.6255 6.13444C10.5888 6.04934 10.5352 5.97223 10.4676 5.9076C10.4001 5.84297 10.32 5.79212 10.232 5.75803C10.144 5.72395 10.05 5.70733 9.95535 5.70911C9.86071 5.7109 9.7674 5.73107 9.68085 5.76844L9.66957 5.77345C9.49468 5.85161 9.35824 5.99355 9.28958 6.16877C9.22091 6.34399 9.22551 6.53848 9.30239 6.71043C9.35785 6.83539 9.44949 6.94187 9.56608 7.01682C9.68267 7.09178 9.81915 7.13195 9.9588 7.13242Z"
                        fill="currentColor"
                      />
                      <path
                        d="M23.4697 22.9712C23.3912 22.9202 23.303 22.8849 23.2103 22.8677C23.1176 22.8504 23.0222 22.8515 22.93 22.8708C22.8377 22.8901 22.7503 22.9272 22.673 22.98C22.5957 23.0328 22.5301 23.1003 22.48 23.1782C21.878 24.0917 21.1892 24.9478 20.4225 25.7352C19.7865 26.3905 19.1008 26.9982 18.3712 27.5532C18.2193 27.6697 18.12 27.8395 18.0948 28.0264C18.0695 28.2132 18.1201 28.4023 18.2359 28.5532C18.2917 28.6276 18.3622 28.6902 18.4433 28.7374C18.5245 28.7846 18.6145 28.8154 18.7081 28.828C18.8016 28.8406 18.8969 28.8347 18.9881 28.8107C19.0793 28.7867 19.1646 28.745 19.2389 28.6882C20.0262 28.0893 20.7664 27.4338 21.4533 26.7272C22.2828 25.8745 23.028 24.9474 23.6789 23.9582C23.7832 23.7997 23.8197 23.6076 23.7806 23.4232C23.7415 23.2387 23.6299 23.0765 23.4697 22.9712Z"
                        fill="currentColor"
                      />
                      <path
                        d="M16.708 28.671L16.667 28.695C16.5021 28.7922 16.3827 28.9482 16.334 29.1299C16.2854 29.3116 16.3114 29.5047 16.4065 29.668C16.4676 29.7751 16.5568 29.8645 16.6649 29.927C16.773 29.9895 16.8962 30.023 17.0219 30.024C17.1482 30.0228 17.2719 29.9882 17.3798 29.924L17.428 29.896C17.5925 29.798 17.7113 29.6413 17.759 29.4591C17.8067 29.277 17.7796 29.0839 17.6834 28.921C17.6367 28.8416 17.5743 28.7721 17.4998 28.7164C17.4253 28.6608 17.3402 28.6202 17.2494 28.5969C17.1586 28.5736 17.064 28.5682 16.9711 28.5809C16.8781 28.5936 16.7887 28.6242 16.708 28.671Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.0648 15.4816C9.85117 15.2668 9.59548 15.096 9.31288 14.9794C9.03028 14.8627 8.72653 14.8026 8.41965 14.8026C8.11277 14.8026 7.80901 14.8627 7.52641 14.9794C7.24381 15.096 6.98812 15.2668 6.77452 15.4816C6.33791 15.9206 6.09375 16.5081 6.09375 17.1196C6.09375 17.7311 6.33791 18.3186 6.77452 18.7576L11.0002 22.9656C11.2139 23.1804 11.4697 23.3511 11.7524 23.4677C12.0351 23.5842 12.3389 23.6443 12.6458 23.6443C12.9527 23.6443 13.2565 23.5842 13.5392 23.4677C13.8219 23.3511 14.0777 23.1804 14.2914 22.9656L23.112 14.1826C23.5486 13.7436 23.7927 13.1562 23.7927 12.5446C23.7927 11.9331 23.5486 11.3457 23.112 10.9066C22.8983 10.6918 22.6425 10.521 22.3598 10.4043C22.0771 10.2876 21.7733 10.2275 21.4663 10.2275C21.1594 10.2275 20.8555 10.2876 20.5728 10.4043C20.2901 10.521 20.0344 10.6918 19.8207 10.9066L12.6412 18.0506L10.0648 15.4816ZM20.834 11.9136C20.9162 11.8308 21.0146 11.7649 21.1234 11.7199C21.2322 11.675 21.3492 11.6518 21.4673 11.6518C21.5855 11.6518 21.7025 11.675 21.8113 11.7199C21.9201 11.7649 22.0185 11.8308 22.1007 11.9136C22.2689 12.0825 22.363 12.3087 22.363 12.5441C22.363 12.7796 22.2689 13.0057 22.1007 13.1746L13.2802 21.9576C13.1979 22.0402 13.0994 22.1059 12.9906 22.1507C12.8818 22.1955 12.7649 22.2186 12.6468 22.2186C12.5287 22.2186 12.4118 22.1955 12.303 22.1507C12.1943 22.1059 12.0958 22.0402 12.0135 21.9576L7.78683 17.7506C7.61901 17.5813 7.5252 17.3551 7.5252 17.1196C7.5252 16.8842 7.61901 16.658 7.78683 16.4886C7.86922 16.406 7.96776 16.3403 8.07663 16.2955C8.1855 16.2507 8.30249 16.2276 8.42067 16.2276C8.53886 16.2276 8.65585 16.2507 8.76472 16.2955C8.87358 16.3403 8.97213 16.406 9.05452 16.4886L12.1407 19.5616C12.2064 19.6278 12.285 19.6804 12.372 19.7163C12.4589 19.7522 12.5524 19.7707 12.6468 19.7707C12.7413 19.7707 12.8347 19.7522 12.9217 19.7163C13.0086 19.6804 13.0873 19.6278 13.153 19.5616L20.834 11.9136Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
                <div className="amenities__content">
                  <h3 className="amenities__title">{t.charac1}</h3>
                  <p className="amenities__desc">{t.characDesc1}</p>
                </div>
              </div>
              <div className="amenities__box">
                <div className="amenities__icone text-right">
                  <span>
                    <svg
                      width={30}
                      height={34}
                      viewBox="0 0 30 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.9241 5.849C24.1438 4.52979 19.5648 2.59614 15.3077 0.098999C15.1979 0.0342357 15.0719 0 14.9436 0C14.8152 0 14.6893 0.0342357 14.5795 0.098999C10.3309 2.61282 5.74914 4.54746 0.962046 5.849C0.809441 5.89179 0.675396 5.98212 0.580388 6.10617C0.48538 6.23022 0.434632 6.38119 0.435891 6.53601V13.816C0.39353 16.6537 0.937333 19.4707 2.03491 22.0993C3.13249 24.7278 4.76139 27.1141 6.82461 29.116C9.96204 32.176 13.6164 33.869 14.9436 33.869C16.2708 33.869 19.9251 32.176 23.0626 29.116C25.1254 27.1139 26.754 24.7275 27.8514 22.099C28.9488 19.4705 29.4925 16.6536 29.4502 13.816V6.53601C29.4515 6.38119 29.4008 6.23022 29.3058 6.10617C29.2107 5.98212 29.0767 5.89179 28.9241 5.849ZM28.0195 13.816C28.0603 16.4651 27.5537 19.0952 26.5299 21.5493C25.5061 24.0035 23.9862 26.2317 22.0605 28.101C18.9754 31.11 15.6769 32.447 14.9436 32.447C14.2102 32.447 10.9118 31.11 7.82564 28.101C5.90036 26.2315 4.38068 24.0032 3.35711 21.5491C2.33353 19.0949 1.827 16.465 1.86769 13.816V7.077C6.45127 5.7833 10.8458 3.92178 14.9436 1.53799C19.0499 3.90571 23.4422 5.76632 28.0195 7.077V13.816Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8.60038 6.99693C8.56698 6.91137 8.51655 6.83309 8.452 6.76661C8.38744 6.70014 8.31003 6.64678 8.22423 6.6096C8.13843 6.57242 8.04593 6.55218 7.95207 6.55C7.8582 6.54782 7.76482 6.56378 7.67731 6.59694C6.39833 7.09694 5.08654 7.55992 3.77987 7.96892C3.6333 8.01604 3.50581 8.10724 3.4157 8.22941C3.32559 8.35158 3.2775 8.49846 3.27833 8.64894V11.4009C3.27833 11.5866 3.35397 11.7646 3.48861 11.8959C3.62325 12.0272 3.80587 12.1009 3.99628 12.1009C4.18669 12.1009 4.36931 12.0272 4.50395 11.8959C4.63859 11.7646 4.71423 11.5866 4.71423 11.4009V9.17091C5.88859 8.79291 7.06192 8.37093 8.20859 7.92393C8.38518 7.84998 8.52507 7.71154 8.5983 7.53828C8.67153 7.36502 8.67227 7.17072 8.60038 6.99693Z"
                        fill="currentColor"
                      />
                      <path
                        d="M9.9588 7.13242C10.0578 7.13256 10.1557 7.1121 10.246 7.07243H10.2593C10.4341 6.99388 10.5703 6.85167 10.6388 6.67631C10.7072 6.50094 10.7025 6.30641 10.6255 6.13444C10.5888 6.04934 10.5352 5.97223 10.4676 5.9076C10.4001 5.84297 10.32 5.79212 10.232 5.75803C10.144 5.72395 10.05 5.70733 9.95535 5.70911C9.86071 5.7109 9.7674 5.73107 9.68085 5.76844L9.66957 5.77345C9.49468 5.85161 9.35824 5.99355 9.28958 6.16877C9.22091 6.34399 9.22551 6.53848 9.30239 6.71043C9.35785 6.83539 9.44949 6.94187 9.56608 7.01682C9.68267 7.09178 9.81915 7.13195 9.9588 7.13242Z"
                        fill="currentColor"
                      />
                      <path
                        d="M23.4697 22.9712C23.3912 22.9202 23.303 22.8849 23.2103 22.8677C23.1176 22.8504 23.0222 22.8515 22.93 22.8708C22.8377 22.8901 22.7503 22.9272 22.673 22.98C22.5957 23.0328 22.5301 23.1003 22.48 23.1782C21.878 24.0917 21.1892 24.9478 20.4225 25.7352C19.7865 26.3905 19.1008 26.9982 18.3712 27.5532C18.2193 27.6697 18.12 27.8395 18.0948 28.0264C18.0695 28.2132 18.1201 28.4023 18.2359 28.5532C18.2917 28.6276 18.3622 28.6902 18.4433 28.7374C18.5245 28.7846 18.6145 28.8154 18.7081 28.828C18.8016 28.8406 18.8969 28.8347 18.9881 28.8107C19.0793 28.7867 19.1646 28.745 19.2389 28.6882C20.0262 28.0893 20.7664 27.4338 21.4533 26.7272C22.2828 25.8745 23.028 24.9474 23.6789 23.9582C23.7832 23.7997 23.8197 23.6076 23.7806 23.4232C23.7415 23.2387 23.6299 23.0765 23.4697 22.9712Z"
                        fill="currentColor"
                      />
                      <path
                        d="M16.708 28.671L16.667 28.695C16.5021 28.7922 16.3827 28.9482 16.334 29.1299C16.2854 29.3116 16.3114 29.5047 16.4065 29.668C16.4676 29.7751 16.5568 29.8645 16.6649 29.927C16.773 29.9895 16.8962 30.023 17.0219 30.024C17.1482 30.0228 17.2719 29.9882 17.3798 29.924L17.428 29.896C17.5925 29.798 17.7113 29.6413 17.759 29.4591C17.8067 29.277 17.7796 29.0839 17.6834 28.921C17.6367 28.8416 17.5743 28.7721 17.4998 28.7164C17.4253 28.6608 17.3402 28.6202 17.2494 28.5969C17.1586 28.5736 17.064 28.5682 16.9711 28.5809C16.8781 28.5936 16.7887 28.6242 16.708 28.671Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.0648 15.4816C9.85117 15.2668 9.59548 15.096 9.31288 14.9794C9.03028 14.8627 8.72653 14.8026 8.41965 14.8026C8.11277 14.8026 7.80901 14.8627 7.52641 14.9794C7.24381 15.096 6.98812 15.2668 6.77452 15.4816C6.33791 15.9206 6.09375 16.5081 6.09375 17.1196C6.09375 17.7311 6.33791 18.3186 6.77452 18.7576L11.0002 22.9656C11.2139 23.1804 11.4697 23.3511 11.7524 23.4677C12.0351 23.5842 12.3389 23.6443 12.6458 23.6443C12.9527 23.6443 13.2565 23.5842 13.5392 23.4677C13.8219 23.3511 14.0777 23.1804 14.2914 22.9656L23.112 14.1826C23.5486 13.7436 23.7927 13.1562 23.7927 12.5446C23.7927 11.9331 23.5486 11.3457 23.112 10.9066C22.8983 10.6918 22.6425 10.521 22.3598 10.4043C22.0771 10.2876 21.7733 10.2275 21.4663 10.2275C21.1594 10.2275 20.8555 10.2876 20.5728 10.4043C20.2901 10.521 20.0344 10.6918 19.8207 10.9066L12.6412 18.0506L10.0648 15.4816ZM20.834 11.9136C20.9162 11.8308 21.0146 11.7649 21.1234 11.7199C21.2322 11.675 21.3492 11.6518 21.4673 11.6518C21.5855 11.6518 21.7025 11.675 21.8113 11.7199C21.9201 11.7649 22.0185 11.8308 22.1007 11.9136C22.2689 12.0825 22.363 12.3087 22.363 12.5441C22.363 12.7796 22.2689 13.0057 22.1007 13.1746L13.2802 21.9576C13.1979 22.0402 13.0994 22.1059 12.9906 22.1507C12.8818 22.1955 12.7649 22.2186 12.6468 22.2186C12.5287 22.2186 12.4118 22.1955 12.303 22.1507C12.1943 22.1059 12.0958 22.0402 12.0135 21.9576L7.78683 17.7506C7.61901 17.5813 7.5252 17.3551 7.5252 17.1196C7.5252 16.8842 7.61901 16.658 7.78683 16.4886C7.86922 16.406 7.96776 16.3403 8.07663 16.2955C8.1855 16.2507 8.30249 16.2276 8.42067 16.2276C8.53886 16.2276 8.65585 16.2507 8.76472 16.2955C8.87358 16.3403 8.97213 16.406 9.05452 16.4886L12.1407 19.5616C12.2064 19.6278 12.285 19.6804 12.372 19.7163C12.4589 19.7522 12.5524 19.7707 12.6468 19.7707C12.7413 19.7707 12.8347 19.7522 12.9217 19.7163C13.0086 19.6804 13.0873 19.6278 13.153 19.5616L20.834 11.9136Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
                <div className="amenities__content">
                  <h3 className="amenities__title">{t.charac3}</h3>
                  <p className="amenities__desc">{t.characDesc2}</p>
                </div>
              </div>
              <div className="amenities__box">
                <div className="amenities__icone text-right">
                  <span>
                    <svg
                      width={30}
                      height={34}
                      viewBox="0 0 30 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.9241 5.849C24.1438 4.52979 19.5648 2.59614 15.3077 0.098999C15.1979 0.0342357 15.0719 0 14.9436 0C14.8152 0 14.6893 0.0342357 14.5795 0.098999C10.3309 2.61282 5.74914 4.54746 0.962046 5.849C0.809441 5.89179 0.675396 5.98212 0.580388 6.10617C0.48538 6.23022 0.434632 6.38119 0.435891 6.53601V13.816C0.39353 16.6537 0.937333 19.4707 2.03491 22.0993C3.13249 24.7278 4.76139 27.1141 6.82461 29.116C9.96204 32.176 13.6164 33.869 14.9436 33.869C16.2708 33.869 19.9251 32.176 23.0626 29.116C25.1254 27.1139 26.754 24.7275 27.8514 22.099C28.9488 19.4705 29.4925 16.6536 29.4502 13.816V6.53601C29.4515 6.38119 29.4008 6.23022 29.3058 6.10617C29.2107 5.98212 29.0767 5.89179 28.9241 5.849ZM28.0195 13.816C28.0603 16.4651 27.5537 19.0952 26.5299 21.5493C25.5061 24.0035 23.9862 26.2317 22.0605 28.101C18.9754 31.11 15.6769 32.447 14.9436 32.447C14.2102 32.447 10.9118 31.11 7.82564 28.101C5.90036 26.2315 4.38068 24.0032 3.35711 21.5491C2.33353 19.0949 1.827 16.465 1.86769 13.816V7.077C6.45127 5.7833 10.8458 3.92178 14.9436 1.53799C19.0499 3.90571 23.4422 5.76632 28.0195 7.077V13.816Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8.60038 6.99693C8.56698 6.91137 8.51655 6.83309 8.452 6.76661C8.38744 6.70014 8.31003 6.64678 8.22423 6.6096C8.13843 6.57242 8.04593 6.55218 7.95207 6.55C7.8582 6.54782 7.76482 6.56378 7.67731 6.59694C6.39833 7.09694 5.08654 7.55992 3.77987 7.96892C3.6333 8.01604 3.50581 8.10724 3.4157 8.22941C3.32559 8.35158 3.2775 8.49846 3.27833 8.64894V11.4009C3.27833 11.5866 3.35397 11.7646 3.48861 11.8959C3.62325 12.0272 3.80587 12.1009 3.99628 12.1009C4.18669 12.1009 4.36931 12.0272 4.50395 11.8959C4.63859 11.7646 4.71423 11.5866 4.71423 11.4009V9.17091C5.88859 8.79291 7.06192 8.37093 8.20859 7.92393C8.38518 7.84998 8.52507 7.71154 8.5983 7.53828C8.67153 7.36502 8.67227 7.17072 8.60038 6.99693Z"
                        fill="currentColor"
                      />
                      <path
                        d="M9.9588 7.13242C10.0578 7.13256 10.1557 7.1121 10.246 7.07243H10.2593C10.4341 6.99388 10.5703 6.85167 10.6388 6.67631C10.7072 6.50094 10.7025 6.30641 10.6255 6.13444C10.5888 6.04934 10.5352 5.97223 10.4676 5.9076C10.4001 5.84297 10.32 5.79212 10.232 5.75803C10.144 5.72395 10.05 5.70733 9.95535 5.70911C9.86071 5.7109 9.7674 5.73107 9.68085 5.76844L9.66957 5.77345C9.49468 5.85161 9.35824 5.99355 9.28958 6.16877C9.22091 6.34399 9.22551 6.53848 9.30239 6.71043C9.35785 6.83539 9.44949 6.94187 9.56608 7.01682C9.68267 7.09178 9.81915 7.13195 9.9588 7.13242Z"
                        fill="currentColor"
                      />
                      <path
                        d="M23.4697 22.9712C23.3912 22.9202 23.303 22.8849 23.2103 22.8677C23.1176 22.8504 23.0222 22.8515 22.93 22.8708C22.8377 22.8901 22.7503 22.9272 22.673 22.98C22.5957 23.0328 22.5301 23.1003 22.48 23.1782C21.878 24.0917 21.1892 24.9478 20.4225 25.7352C19.7865 26.3905 19.1008 26.9982 18.3712 27.5532C18.2193 27.6697 18.12 27.8395 18.0948 28.0264C18.0695 28.2132 18.1201 28.4023 18.2359 28.5532C18.2917 28.6276 18.3622 28.6902 18.4433 28.7374C18.5245 28.7846 18.6145 28.8154 18.7081 28.828C18.8016 28.8406 18.8969 28.8347 18.9881 28.8107C19.0793 28.7867 19.1646 28.745 19.2389 28.6882C20.0262 28.0893 20.7664 27.4338 21.4533 26.7272C22.2828 25.8745 23.028 24.9474 23.6789 23.9582C23.7832 23.7997 23.8197 23.6076 23.7806 23.4232C23.7415 23.2387 23.6299 23.0765 23.4697 22.9712Z"
                        fill="currentColor"
                      />
                      <path
                        d="M16.708 28.671L16.667 28.695C16.5021 28.7922 16.3827 28.9482 16.334 29.1299C16.2854 29.3116 16.3114 29.5047 16.4065 29.668C16.4676 29.7751 16.5568 29.8645 16.6649 29.927C16.773 29.9895 16.8962 30.023 17.0219 30.024C17.1482 30.0228 17.2719 29.9882 17.3798 29.924L17.428 29.896C17.5925 29.798 17.7113 29.6413 17.759 29.4591C17.8067 29.277 17.7796 29.0839 17.6834 28.921C17.6367 28.8416 17.5743 28.7721 17.4998 28.7164C17.4253 28.6608 17.3402 28.6202 17.2494 28.5969C17.1586 28.5736 17.064 28.5682 16.9711 28.5809C16.8781 28.5936 16.7887 28.6242 16.708 28.671Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.0648 15.4816C9.85117 15.2668 9.59548 15.096 9.31288 14.9794C9.03028 14.8627 8.72653 14.8026 8.41965 14.8026C8.11277 14.8026 7.80901 14.8627 7.52641 14.9794C7.24381 15.096 6.98812 15.2668 6.77452 15.4816C6.33791 15.9206 6.09375 16.5081 6.09375 17.1196C6.09375 17.7311 6.33791 18.3186 6.77452 18.7576L11.0002 22.9656C11.2139 23.1804 11.4697 23.3511 11.7524 23.4677C12.0351 23.5842 12.3389 23.6443 12.6458 23.6443C12.9527 23.6443 13.2565 23.5842 13.5392 23.4677C13.8219 23.3511 14.0777 23.1804 14.2914 22.9656L23.112 14.1826C23.5486 13.7436 23.7927 13.1562 23.7927 12.5446C23.7927 11.9331 23.5486 11.3457 23.112 10.9066C22.8983 10.6918 22.6425 10.521 22.3598 10.4043C22.0771 10.2876 21.7733 10.2275 21.4663 10.2275C21.1594 10.2275 20.8555 10.2876 20.5728 10.4043C20.2901 10.521 20.0344 10.6918 19.8207 10.9066L12.6412 18.0506L10.0648 15.4816ZM20.834 11.9136C20.9162 11.8308 21.0146 11.7649 21.1234 11.7199C21.2322 11.675 21.3492 11.6518 21.4673 11.6518C21.5855 11.6518 21.7025 11.675 21.8113 11.7199C21.9201 11.7649 22.0185 11.8308 22.1007 11.9136C22.2689 12.0825 22.363 12.3087 22.363 12.5441C22.363 12.7796 22.2689 13.0057 22.1007 13.1746L13.2802 21.9576C13.1979 22.0402 13.0994 22.1059 12.9906 22.1507C12.8818 22.1955 12.7649 22.2186 12.6468 22.2186C12.5287 22.2186 12.4118 22.1955 12.303 22.1507C12.1943 22.1059 12.0958 22.0402 12.0135 21.9576L7.78683 17.7506C7.61901 17.5813 7.5252 17.3551 7.5252 17.1196C7.5252 16.8842 7.61901 16.658 7.78683 16.4886C7.86922 16.406 7.96776 16.3403 8.07663 16.2955C8.1855 16.2507 8.30249 16.2276 8.42067 16.2276C8.53886 16.2276 8.65585 16.2507 8.76472 16.2955C8.87358 16.3403 8.97213 16.406 9.05452 16.4886L12.1407 19.5616C12.2064 19.6278 12.285 19.6804 12.372 19.7163C12.4589 19.7522 12.5524 19.7707 12.6468 19.7707C12.7413 19.7707 12.8347 19.7522 12.9217 19.7163C13.0086 19.6804 13.0873 19.6278 13.153 19.5616L20.834 11.9136Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
                <div className="amenities__content">
                  <h3 className="amenities__title">{t.charac2}</h3>
                  <p className="amenities__desc">{t.characDesc3}</p>
                </div>
              </div>
              <div className="amenities__box">
                <div className="amenities__icone text-right">
                  <span>
                    <svg
                      width={30}
                      height={34}
                      viewBox="0 0 30 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.9241 5.849C24.1438 4.52979 19.5648 2.59614 15.3077 0.098999C15.1979 0.0342357 15.0719 0 14.9436 0C14.8152 0 14.6893 0.0342357 14.5795 0.098999C10.3309 2.61282 5.74914 4.54746 0.962046 5.849C0.809441 5.89179 0.675396 5.98212 0.580388 6.10617C0.48538 6.23022 0.434632 6.38119 0.435891 6.53601V13.816C0.39353 16.6537 0.937333 19.4707 2.03491 22.0993C3.13249 24.7278 4.76139 27.1141 6.82461 29.116C9.96204 32.176 13.6164 33.869 14.9436 33.869C16.2708 33.869 19.9251 32.176 23.0626 29.116C25.1254 27.1139 26.754 24.7275 27.8514 22.099C28.9488 19.4705 29.4925 16.6536 29.4502 13.816V6.53601C29.4515 6.38119 29.4008 6.23022 29.3058 6.10617C29.2107 5.98212 29.0767 5.89179 28.9241 5.849ZM28.0195 13.816C28.0603 16.4651 27.5537 19.0952 26.5299 21.5493C25.5061 24.0035 23.9862 26.2317 22.0605 28.101C18.9754 31.11 15.6769 32.447 14.9436 32.447C14.2102 32.447 10.9118 31.11 7.82564 28.101C5.90036 26.2315 4.38068 24.0032 3.35711 21.5491C2.33353 19.0949 1.827 16.465 1.86769 13.816V7.077C6.45127 5.7833 10.8458 3.92178 14.9436 1.53799C19.0499 3.90571 23.4422 5.76632 28.0195 7.077V13.816Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8.60038 6.99693C8.56698 6.91137 8.51655 6.83309 8.452 6.76661C8.38744 6.70014 8.31003 6.64678 8.22423 6.6096C8.13843 6.57242 8.04593 6.55218 7.95207 6.55C7.8582 6.54782 7.76482 6.56378 7.67731 6.59694C6.39833 7.09694 5.08654 7.55992 3.77987 7.96892C3.6333 8.01604 3.50581 8.10724 3.4157 8.22941C3.32559 8.35158 3.2775 8.49846 3.27833 8.64894V11.4009C3.27833 11.5866 3.35397 11.7646 3.48861 11.8959C3.62325 12.0272 3.80587 12.1009 3.99628 12.1009C4.18669 12.1009 4.36931 12.0272 4.50395 11.8959C4.63859 11.7646 4.71423 11.5866 4.71423 11.4009V9.17091C5.88859 8.79291 7.06192 8.37093 8.20859 7.92393C8.38518 7.84998 8.52507 7.71154 8.5983 7.53828C8.67153 7.36502 8.67227 7.17072 8.60038 6.99693Z"
                        fill="currentColor"
                      />
                      <path
                        d="M9.9588 7.13242C10.0578 7.13256 10.1557 7.1121 10.246 7.07243H10.2593C10.4341 6.99388 10.5703 6.85167 10.6388 6.67631C10.7072 6.50094 10.7025 6.30641 10.6255 6.13444C10.5888 6.04934 10.5352 5.97223 10.4676 5.9076C10.4001 5.84297 10.32 5.79212 10.232 5.75803C10.144 5.72395 10.05 5.70733 9.95535 5.70911C9.86071 5.7109 9.7674 5.73107 9.68085 5.76844L9.66957 5.77345C9.49468 5.85161 9.35824 5.99355 9.28958 6.16877C9.22091 6.34399 9.22551 6.53848 9.30239 6.71043C9.35785 6.83539 9.44949 6.94187 9.56608 7.01682C9.68267 7.09178 9.81915 7.13195 9.9588 7.13242Z"
                        fill="currentColor"
                      />
                      <path
                        d="M23.4697 22.9712C23.3912 22.9202 23.303 22.8849 23.2103 22.8677C23.1176 22.8504 23.0222 22.8515 22.93 22.8708C22.8377 22.8901 22.7503 22.9272 22.673 22.98C22.5957 23.0328 22.5301 23.1003 22.48 23.1782C21.878 24.0917 21.1892 24.9478 20.4225 25.7352C19.7865 26.3905 19.1008 26.9982 18.3712 27.5532C18.2193 27.6697 18.12 27.8395 18.0948 28.0264C18.0695 28.2132 18.1201 28.4023 18.2359 28.5532C18.2917 28.6276 18.3622 28.6902 18.4433 28.7374C18.5245 28.7846 18.6145 28.8154 18.7081 28.828C18.8016 28.8406 18.8969 28.8347 18.9881 28.8107C19.0793 28.7867 19.1646 28.745 19.2389 28.6882C20.0262 28.0893 20.7664 27.4338 21.4533 26.7272C22.2828 25.8745 23.028 24.9474 23.6789 23.9582C23.7832 23.7997 23.8197 23.6076 23.7806 23.4232C23.7415 23.2387 23.6299 23.0765 23.4697 22.9712Z"
                        fill="currentColor"
                      />
                      <path
                        d="M16.708 28.671L16.667 28.695C16.5021 28.7922 16.3827 28.9482 16.334 29.1299C16.2854 29.3116 16.3114 29.5047 16.4065 29.668C16.4676 29.7751 16.5568 29.8645 16.6649 29.927C16.773 29.9895 16.8962 30.023 17.0219 30.024C17.1482 30.0228 17.2719 29.9882 17.3798 29.924L17.428 29.896C17.5925 29.798 17.7113 29.6413 17.759 29.4591C17.8067 29.277 17.7796 29.0839 17.6834 28.921C17.6367 28.8416 17.5743 28.7721 17.4998 28.7164C17.4253 28.6608 17.3402 28.6202 17.2494 28.5969C17.1586 28.5736 17.064 28.5682 16.9711 28.5809C16.8781 28.5936 16.7887 28.6242 16.708 28.671Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.0648 15.4816C9.85117 15.2668 9.59548 15.096 9.31288 14.9794C9.03028 14.8627 8.72653 14.8026 8.41965 14.8026C8.11277 14.8026 7.80901 14.8627 7.52641 14.9794C7.24381 15.096 6.98812 15.2668 6.77452 15.4816C6.33791 15.9206 6.09375 16.5081 6.09375 17.1196C6.09375 17.7311 6.33791 18.3186 6.77452 18.7576L11.0002 22.9656C11.2139 23.1804 11.4697 23.3511 11.7524 23.4677C12.0351 23.5842 12.3389 23.6443 12.6458 23.6443C12.9527 23.6443 13.2565 23.5842 13.5392 23.4677C13.8219 23.3511 14.0777 23.1804 14.2914 22.9656L23.112 14.1826C23.5486 13.7436 23.7927 13.1562 23.7927 12.5446C23.7927 11.9331 23.5486 11.3457 23.112 10.9066C22.8983 10.6918 22.6425 10.521 22.3598 10.4043C22.0771 10.2876 21.7733 10.2275 21.4663 10.2275C21.1594 10.2275 20.8555 10.2876 20.5728 10.4043C20.2901 10.521 20.0344 10.6918 19.8207 10.9066L12.6412 18.0506L10.0648 15.4816ZM20.834 11.9136C20.9162 11.8308 21.0146 11.7649 21.1234 11.7199C21.2322 11.675 21.3492 11.6518 21.4673 11.6518C21.5855 11.6518 21.7025 11.675 21.8113 11.7199C21.9201 11.7649 22.0185 11.8308 22.1007 11.9136C22.2689 12.0825 22.363 12.3087 22.363 12.5441C22.363 12.7796 22.2689 13.0057 22.1007 13.1746L13.2802 21.9576C13.1979 22.0402 13.0994 22.1059 12.9906 22.1507C12.8818 22.1955 12.7649 22.2186 12.6468 22.2186C12.5287 22.2186 12.4118 22.1955 12.303 22.1507C12.1943 22.1059 12.0958 22.0402 12.0135 21.9576L7.78683 17.7506C7.61901 17.5813 7.5252 17.3551 7.5252 17.1196C7.5252 16.8842 7.61901 16.658 7.78683 16.4886C7.86922 16.406 7.96776 16.3403 8.07663 16.2955C8.1855 16.2507 8.30249 16.2276 8.42067 16.2276C8.53886 16.2276 8.65585 16.2507 8.76472 16.2955C8.87358 16.3403 8.97213 16.406 9.05452 16.4886L12.1407 19.5616C12.2064 19.6278 12.285 19.6804 12.372 19.7163C12.4589 19.7522 12.5524 19.7707 12.6468 19.7707C12.7413 19.7707 12.8347 19.7522 12.9217 19.7163C13.0086 19.6804 13.0873 19.6278 13.153 19.5616L20.834 11.9136Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
                <div className="amenities__content">
                  <h3 className="amenities__title">{t.charac4}</h3>
                  <p className="amenities__desc">{t.characDesc4}</p>
                </div>
              </div>
              <div className="amenities__box">
                <div className="amenities__icone text-right">
                  <span>
                    <svg
                      width={30}
                      height={34}
                      viewBox="0 0 30 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.9241 5.849C24.1438 4.52979 19.5648 2.59614 15.3077 0.098999C15.1979 0.0342357 15.0719 0 14.9436 0C14.8152 0 14.6893 0.0342357 14.5795 0.098999C10.3309 2.61282 5.74914 4.54746 0.962046 5.849C0.809441 5.89179 0.675396 5.98212 0.580388 6.10617C0.48538 6.23022 0.434632 6.38119 0.435891 6.53601V13.816C0.39353 16.6537 0.937333 19.4707 2.03491 22.0993C3.13249 24.7278 4.76139 27.1141 6.82461 29.116C9.96204 32.176 13.6164 33.869 14.9436 33.869C16.2708 33.869 19.9251 32.176 23.0626 29.116C25.1254 27.1139 26.754 24.7275 27.8514 22.099C28.9488 19.4705 29.4925 16.6536 29.4502 13.816V6.53601C29.4515 6.38119 29.4008 6.23022 29.3058 6.10617C29.2107 5.98212 29.0767 5.89179 28.9241 5.849ZM28.0195 13.816C28.0603 16.4651 27.5537 19.0952 26.5299 21.5493C25.5061 24.0035 23.9862 26.2317 22.0605 28.101C18.9754 31.11 15.6769 32.447 14.9436 32.447C14.2102 32.447 10.9118 31.11 7.82564 28.101C5.90036 26.2315 4.38068 24.0032 3.35711 21.5491C2.33353 19.0949 1.827 16.465 1.86769 13.816V7.077C6.45127 5.7833 10.8458 3.92178 14.9436 1.53799C19.0499 3.90571 23.4422 5.76632 28.0195 7.077V13.816Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8.60038 6.99693C8.56698 6.91137 8.51655 6.83309 8.452 6.76661C8.38744 6.70014 8.31003 6.64678 8.22423 6.6096C8.13843 6.57242 8.04593 6.55218 7.95207 6.55C7.8582 6.54782 7.76482 6.56378 7.67731 6.59694C6.39833 7.09694 5.08654 7.55992 3.77987 7.96892C3.6333 8.01604 3.50581 8.10724 3.4157 8.22941C3.32559 8.35158 3.2775 8.49846 3.27833 8.64894V11.4009C3.27833 11.5866 3.35397 11.7646 3.48861 11.8959C3.62325 12.0272 3.80587 12.1009 3.99628 12.1009C4.18669 12.1009 4.36931 12.0272 4.50395 11.8959C4.63859 11.7646 4.71423 11.5866 4.71423 11.4009V9.17091C5.88859 8.79291 7.06192 8.37093 8.20859 7.92393C8.38518 7.84998 8.52507 7.71154 8.5983 7.53828C8.67153 7.36502 8.67227 7.17072 8.60038 6.99693Z"
                        fill="currentColor"
                      />
                      <path
                        d="M9.9588 7.13242C10.0578 7.13256 10.1557 7.1121 10.246 7.07243H10.2593C10.4341 6.99388 10.5703 6.85167 10.6388 6.67631C10.7072 6.50094 10.7025 6.30641 10.6255 6.13444C10.5888 6.04934 10.5352 5.97223 10.4676 5.9076C10.4001 5.84297 10.32 5.79212 10.232 5.75803C10.144 5.72395 10.05 5.70733 9.95535 5.70911C9.86071 5.7109 9.7674 5.73107 9.68085 5.76844L9.66957 5.77345C9.49468 5.85161 9.35824 5.99355 9.28958 6.16877C9.22091 6.34399 9.22551 6.53848 9.30239 6.71043C9.35785 6.83539 9.44949 6.94187 9.56608 7.01682C9.68267 7.09178 9.81915 7.13195 9.9588 7.13242Z"
                        fill="currentColor"
                      />
                      <path
                        d="M23.4697 22.9712C23.3912 22.9202 23.303 22.8849 23.2103 22.8677C23.1176 22.8504 23.0222 22.8515 22.93 22.8708C22.8377 22.8901 22.7503 22.9272 22.673 22.98C22.5957 23.0328 22.5301 23.1003 22.48 23.1782C21.878 24.0917 21.1892 24.9478 20.4225 25.7352C19.7865 26.3905 19.1008 26.9982 18.3712 27.5532C18.2193 27.6697 18.12 27.8395 18.0948 28.0264C18.0695 28.2132 18.1201 28.4023 18.2359 28.5532C18.2917 28.6276 18.3622 28.6902 18.4433 28.7374C18.5245 28.7846 18.6145 28.8154 18.7081 28.828C18.8016 28.8406 18.8969 28.8347 18.9881 28.8107C19.0793 28.7867 19.1646 28.745 19.2389 28.6882C20.0262 28.0893 20.7664 27.4338 21.4533 26.7272C22.2828 25.8745 23.028 24.9474 23.6789 23.9582C23.7832 23.7997 23.8197 23.6076 23.7806 23.4232C23.7415 23.2387 23.6299 23.0765 23.4697 22.9712Z"
                        fill="currentColor"
                      />
                      <path
                        d="M16.708 28.671L16.667 28.695C16.5021 28.7922 16.3827 28.9482 16.334 29.1299C16.2854 29.3116 16.3114 29.5047 16.4065 29.668C16.4676 29.7751 16.5568 29.8645 16.6649 29.927C16.773 29.9895 16.8962 30.023 17.0219 30.024C17.1482 30.0228 17.2719 29.9882 17.3798 29.924L17.428 29.896C17.5925 29.798 17.7113 29.6413 17.759 29.4591C17.8067 29.277 17.7796 29.0839 17.6834 28.921C17.6367 28.8416 17.5743 28.7721 17.4998 28.7164C17.4253 28.6608 17.3402 28.6202 17.2494 28.5969C17.1586 28.5736 17.064 28.5682 16.9711 28.5809C16.8781 28.5936 16.7887 28.6242 16.708 28.671Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.0648 15.4816C9.85117 15.2668 9.59548 15.096 9.31288 14.9794C9.03028 14.8627 8.72653 14.8026 8.41965 14.8026C8.11277 14.8026 7.80901 14.8627 7.52641 14.9794C7.24381 15.096 6.98812 15.2668 6.77452 15.4816C6.33791 15.9206 6.09375 16.5081 6.09375 17.1196C6.09375 17.7311 6.33791 18.3186 6.77452 18.7576L11.0002 22.9656C11.2139 23.1804 11.4697 23.3511 11.7524 23.4677C12.0351 23.5842 12.3389 23.6443 12.6458 23.6443C12.9527 23.6443 13.2565 23.5842 13.5392 23.4677C13.8219 23.3511 14.0777 23.1804 14.2914 22.9656L23.112 14.1826C23.5486 13.7436 23.7927 13.1562 23.7927 12.5446C23.7927 11.9331 23.5486 11.3457 23.112 10.9066C22.8983 10.6918 22.6425 10.521 22.3598 10.4043C22.0771 10.2876 21.7733 10.2275 21.4663 10.2275C21.1594 10.2275 20.8555 10.2876 20.5728 10.4043C20.2901 10.521 20.0344 10.6918 19.8207 10.9066L12.6412 18.0506L10.0648 15.4816ZM20.834 11.9136C20.9162 11.8308 21.0146 11.7649 21.1234 11.7199C21.2322 11.675 21.3492 11.6518 21.4673 11.6518C21.5855 11.6518 21.7025 11.675 21.8113 11.7199C21.9201 11.7649 22.0185 11.8308 22.1007 11.9136C22.2689 12.0825 22.363 12.3087 22.363 12.5441C22.363 12.7796 22.2689 13.0057 22.1007 13.1746L13.2802 21.9576C13.1979 22.0402 13.0994 22.1059 12.9906 22.1507C12.8818 22.1955 12.7649 22.2186 12.6468 22.2186C12.5287 22.2186 12.4118 22.1955 12.303 22.1507C12.1943 22.1059 12.0958 22.0402 12.0135 21.9576L7.78683 17.7506C7.61901 17.5813 7.5252 17.3551 7.5252 17.1196C7.5252 16.8842 7.61901 16.658 7.78683 16.4886C7.86922 16.406 7.96776 16.3403 8.07663 16.2955C8.1855 16.2507 8.30249 16.2276 8.42067 16.2276C8.53886 16.2276 8.65585 16.2507 8.76472 16.2955C8.87358 16.3403 8.97213 16.406 9.05452 16.4886L12.1407 19.5616C12.2064 19.6278 12.285 19.6804 12.372 19.7163C12.4589 19.7522 12.5524 19.7707 12.6468 19.7707C12.7413 19.7707 12.8347 19.7522 12.9217 19.7163C13.0086 19.6804 13.0873 19.6278 13.153 19.5616L20.834 11.9136Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
                <div className="amenities__content">
                  <h3 className="amenities__title">{t.charac6}</h3>
                  <p className="amenities__desc">{t.characDesc5}</p>
                </div>
              </div>
              <div className="amenities__box">
                <div className="amenities__icone text-right">
                  <span>
                    <svg
                      width={30}
                      height={34}
                      viewBox="0 0 30 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.9241 5.849C24.1438 4.52979 19.5648 2.59614 15.3077 0.098999C15.1979 0.0342357 15.0719 0 14.9436 0C14.8152 0 14.6893 0.0342357 14.5795 0.098999C10.3309 2.61282 5.74914 4.54746 0.962046 5.849C0.809441 5.89179 0.675396 5.98212 0.580388 6.10617C0.48538 6.23022 0.434632 6.38119 0.435891 6.53601V13.816C0.39353 16.6537 0.937333 19.4707 2.03491 22.0993C3.13249 24.7278 4.76139 27.1141 6.82461 29.116C9.96204 32.176 13.6164 33.869 14.9436 33.869C16.2708 33.869 19.9251 32.176 23.0626 29.116C25.1254 27.1139 26.754 24.7275 27.8514 22.099C28.9488 19.4705 29.4925 16.6536 29.4502 13.816V6.53601C29.4515 6.38119 29.4008 6.23022 29.3058 6.10617C29.2107 5.98212 29.0767 5.89179 28.9241 5.849ZM28.0195 13.816C28.0603 16.4651 27.5537 19.0952 26.5299 21.5493C25.5061 24.0035 23.9862 26.2317 22.0605 28.101C18.9754 31.11 15.6769 32.447 14.9436 32.447C14.2102 32.447 10.9118 31.11 7.82564 28.101C5.90036 26.2315 4.38068 24.0032 3.35711 21.5491C2.33353 19.0949 1.827 16.465 1.86769 13.816V7.077C6.45127 5.7833 10.8458 3.92178 14.9436 1.53799C19.0499 3.90571 23.4422 5.76632 28.0195 7.077V13.816Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8.60038 6.99693C8.56698 6.91137 8.51655 6.83309 8.452 6.76661C8.38744 6.70014 8.31003 6.64678 8.22423 6.6096C8.13843 6.57242 8.04593 6.55218 7.95207 6.55C7.8582 6.54782 7.76482 6.56378 7.67731 6.59694C6.39833 7.09694 5.08654 7.55992 3.77987 7.96892C3.6333 8.01604 3.50581 8.10724 3.4157 8.22941C3.32559 8.35158 3.2775 8.49846 3.27833 8.64894V11.4009C3.27833 11.5866 3.35397 11.7646 3.48861 11.8959C3.62325 12.0272 3.80587 12.1009 3.99628 12.1009C4.18669 12.1009 4.36931 12.0272 4.50395 11.8959C4.63859 11.7646 4.71423 11.5866 4.71423 11.4009V9.17091C5.88859 8.79291 7.06192 8.37093 8.20859 7.92393C8.38518 7.84998 8.52507 7.71154 8.5983 7.53828C8.67153 7.36502 8.67227 7.17072 8.60038 6.99693Z"
                        fill="currentColor"
                      />
                      <path
                        d="M9.9588 7.13242C10.0578 7.13256 10.1557 7.1121 10.246 7.07243H10.2593C10.4341 6.99388 10.5703 6.85167 10.6388 6.67631C10.7072 6.50094 10.7025 6.30641 10.6255 6.13444C10.5888 6.04934 10.5352 5.97223 10.4676 5.9076C10.4001 5.84297 10.32 5.79212 10.232 5.75803C10.144 5.72395 10.05 5.70733 9.95535 5.70911C9.86071 5.7109 9.7674 5.73107 9.68085 5.76844L9.66957 5.77345C9.49468 5.85161 9.35824 5.99355 9.28958 6.16877C9.22091 6.34399 9.22551 6.53848 9.30239 6.71043C9.35785 6.83539 9.44949 6.94187 9.56608 7.01682C9.68267 7.09178 9.81915 7.13195 9.9588 7.13242Z"
                        fill="currentColor"
                      />
                      <path
                        d="M23.4697 22.9712C23.3912 22.9202 23.303 22.8849 23.2103 22.8677C23.1176 22.8504 23.0222 22.8515 22.93 22.8708C22.8377 22.8901 22.7503 22.9272 22.673 22.98C22.5957 23.0328 22.5301 23.1003 22.48 23.1782C21.878 24.0917 21.1892 24.9478 20.4225 25.7352C19.7865 26.3905 19.1008 26.9982 18.3712 27.5532C18.2193 27.6697 18.12 27.8395 18.0948 28.0264C18.0695 28.2132 18.1201 28.4023 18.2359 28.5532C18.2917 28.6276 18.3622 28.6902 18.4433 28.7374C18.5245 28.7846 18.6145 28.8154 18.7081 28.828C18.8016 28.8406 18.8969 28.8347 18.9881 28.8107C19.0793 28.7867 19.1646 28.745 19.2389 28.6882C20.0262 28.0893 20.7664 27.4338 21.4533 26.7272C22.2828 25.8745 23.028 24.9474 23.6789 23.9582C23.7832 23.7997 23.8197 23.6076 23.7806 23.4232C23.7415 23.2387 23.6299 23.0765 23.4697 22.9712Z"
                        fill="currentColor"
                      />
                      <path
                        d="M16.708 28.671L16.667 28.695C16.5021 28.7922 16.3827 28.9482 16.334 29.1299C16.2854 29.3116 16.3114 29.5047 16.4065 29.668C16.4676 29.7751 16.5568 29.8645 16.6649 29.927C16.773 29.9895 16.8962 30.023 17.0219 30.024C17.1482 30.0228 17.2719 29.9882 17.3798 29.924L17.428 29.896C17.5925 29.798 17.7113 29.6413 17.759 29.4591C17.8067 29.277 17.7796 29.0839 17.6834 28.921C17.6367 28.8416 17.5743 28.7721 17.4998 28.7164C17.4253 28.6608 17.3402 28.6202 17.2494 28.5969C17.1586 28.5736 17.064 28.5682 16.9711 28.5809C16.8781 28.5936 16.7887 28.6242 16.708 28.671Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.0648 15.4816C9.85117 15.2668 9.59548 15.096 9.31288 14.9794C9.03028 14.8627 8.72653 14.8026 8.41965 14.8026C8.11277 14.8026 7.80901 14.8627 7.52641 14.9794C7.24381 15.096 6.98812 15.2668 6.77452 15.4816C6.33791 15.9206 6.09375 16.5081 6.09375 17.1196C6.09375 17.7311 6.33791 18.3186 6.77452 18.7576L11.0002 22.9656C11.2139 23.1804 11.4697 23.3511 11.7524 23.4677C12.0351 23.5842 12.3389 23.6443 12.6458 23.6443C12.9527 23.6443 13.2565 23.5842 13.5392 23.4677C13.8219 23.3511 14.0777 23.1804 14.2914 22.9656L23.112 14.1826C23.5486 13.7436 23.7927 13.1562 23.7927 12.5446C23.7927 11.9331 23.5486 11.3457 23.112 10.9066C22.8983 10.6918 22.6425 10.521 22.3598 10.4043C22.0771 10.2876 21.7733 10.2275 21.4663 10.2275C21.1594 10.2275 20.8555 10.2876 20.5728 10.4043C20.2901 10.521 20.0344 10.6918 19.8207 10.9066L12.6412 18.0506L10.0648 15.4816ZM20.834 11.9136C20.9162 11.8308 21.0146 11.7649 21.1234 11.7199C21.2322 11.675 21.3492 11.6518 21.4673 11.6518C21.5855 11.6518 21.7025 11.675 21.8113 11.7199C21.9201 11.7649 22.0185 11.8308 22.1007 11.9136C22.2689 12.0825 22.363 12.3087 22.363 12.5441C22.363 12.7796 22.2689 13.0057 22.1007 13.1746L13.2802 21.9576C13.1979 22.0402 13.0994 22.1059 12.9906 22.1507C12.8818 22.1955 12.7649 22.2186 12.6468 22.2186C12.5287 22.2186 12.4118 22.1955 12.303 22.1507C12.1943 22.1059 12.0958 22.0402 12.0135 21.9576L7.78683 17.7506C7.61901 17.5813 7.5252 17.3551 7.5252 17.1196C7.5252 16.8842 7.61901 16.658 7.78683 16.4886C7.86922 16.406 7.96776 16.3403 8.07663 16.2955C8.1855 16.2507 8.30249 16.2276 8.42067 16.2276C8.53886 16.2276 8.65585 16.2507 8.76472 16.2955C8.87358 16.3403 8.97213 16.406 9.05452 16.4886L12.1407 19.5616C12.2064 19.6278 12.285 19.6804 12.372 19.7163C12.4589 19.7522 12.5524 19.7707 12.6468 19.7707C12.7413 19.7707 12.8347 19.7522 12.9217 19.7163C13.0086 19.6804 13.0873 19.6278 13.153 19.5616L20.834 11.9136Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
                <div className="amenities__content">
                  <h3 className="amenities__title">{t.charac5}</h3>
                  <p className="amenities__desc">{t.characDesc6}</p>
                </div>
              </div>
              <div className="amenities__box">
                <div className="amenities__icone text-right">
                  <span>
                    <svg
                      width={30}
                      height={34}
                      viewBox="0 0 30 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.9241 5.849C24.1438 4.52979 19.5648 2.59614 15.3077 0.098999C15.1979 0.0342357 15.0719 0 14.9436 0C14.8152 0 14.6893 0.0342357 14.5795 0.098999C10.3309 2.61282 5.74914 4.54746 0.962046 5.849C0.809441 5.89179 0.675396 5.98212 0.580388 6.10617C0.48538 6.23022 0.434632 6.38119 0.435891 6.53601V13.816C0.39353 16.6537 0.937333 19.4707 2.03491 22.0993C3.13249 24.7278 4.76139 27.1141 6.82461 29.116C9.96204 32.176 13.6164 33.869 14.9436 33.869C16.2708 33.869 19.9251 32.176 23.0626 29.116C25.1254 27.1139 26.754 24.7275 27.8514 22.099C28.9488 19.4705 29.4925 16.6536 29.4502 13.816V6.53601C29.4515 6.38119 29.4008 6.23022 29.3058 6.10617C29.2107 5.98212 29.0767 5.89179 28.9241 5.849ZM28.0195 13.816C28.0603 16.4651 27.5537 19.0952 26.5299 21.5493C25.5061 24.0035 23.9862 26.2317 22.0605 28.101C18.9754 31.11 15.6769 32.447 14.9436 32.447C14.2102 32.447 10.9118 31.11 7.82564 28.101C5.90036 26.2315 4.38068 24.0032 3.35711 21.5491C2.33353 19.0949 1.827 16.465 1.86769 13.816V7.077C6.45127 5.7833 10.8458 3.92178 14.9436 1.53799C19.0499 3.90571 23.4422 5.76632 28.0195 7.077V13.816Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8.60038 6.99693C8.56698 6.91137 8.51655 6.83309 8.452 6.76661C8.38744 6.70014 8.31003 6.64678 8.22423 6.6096C8.13843 6.57242 8.04593 6.55218 7.95207 6.55C7.8582 6.54782 7.76482 6.56378 7.67731 6.59694C6.39833 7.09694 5.08654 7.55992 3.77987 7.96892C3.6333 8.01604 3.50581 8.10724 3.4157 8.22941C3.32559 8.35158 3.2775 8.49846 3.27833 8.64894V11.4009C3.27833 11.5866 3.35397 11.7646 3.48861 11.8959C3.62325 12.0272 3.80587 12.1009 3.99628 12.1009C4.18669 12.1009 4.36931 12.0272 4.50395 11.8959C4.63859 11.7646 4.71423 11.5866 4.71423 11.4009V9.17091C5.88859 8.79291 7.06192 8.37093 8.20859 7.92393C8.38518 7.84998 8.52507 7.71154 8.5983 7.53828C8.67153 7.36502 8.67227 7.17072 8.60038 6.99693Z"
                        fill="currentColor"
                      />
                      <path
                        d="M9.9588 7.13242C10.0578 7.13256 10.1557 7.1121 10.246 7.07243H10.2593C10.4341 6.99388 10.5703 6.85167 10.6388 6.67631C10.7072 6.50094 10.7025 6.30641 10.6255 6.13444C10.5888 6.04934 10.5352 5.97223 10.4676 5.9076C10.4001 5.84297 10.32 5.79212 10.232 5.75803C10.144 5.72395 10.05 5.70733 9.95535 5.70911C9.86071 5.7109 9.7674 5.73107 9.68085 5.76844L9.66957 5.77345C9.49468 5.85161 9.35824 5.99355 9.28958 6.16877C9.22091 6.34399 9.22551 6.53848 9.30239 6.71043C9.35785 6.83539 9.44949 6.94187 9.56608 7.01682C9.68267 7.09178 9.81915 7.13195 9.9588 7.13242Z"
                        fill="currentColor"
                      />
                      <path
                        d="M23.4697 22.9712C23.3912 22.9202 23.303 22.8849 23.2103 22.8677C23.1176 22.8504 23.0222 22.8515 22.93 22.8708C22.8377 22.8901 22.7503 22.9272 22.673 22.98C22.5957 23.0328 22.5301 23.1003 22.48 23.1782C21.878 24.0917 21.1892 24.9478 20.4225 25.7352C19.7865 26.3905 19.1008 26.9982 18.3712 27.5532C18.2193 27.6697 18.12 27.8395 18.0948 28.0264C18.0695 28.2132 18.1201 28.4023 18.2359 28.5532C18.2917 28.6276 18.3622 28.6902 18.4433 28.7374C18.5245 28.7846 18.6145 28.8154 18.7081 28.828C18.8016 28.8406 18.8969 28.8347 18.9881 28.8107C19.0793 28.7867 19.1646 28.745 19.2389 28.6882C20.0262 28.0893 20.7664 27.4338 21.4533 26.7272C22.2828 25.8745 23.028 24.9474 23.6789 23.9582C23.7832 23.7997 23.8197 23.6076 23.7806 23.4232C23.7415 23.2387 23.6299 23.0765 23.4697 22.9712Z"
                        fill="currentColor"
                      />
                      <path
                        d="M16.708 28.671L16.667 28.695C16.5021 28.7922 16.3827 28.9482 16.334 29.1299C16.2854 29.3116 16.3114 29.5047 16.4065 29.668C16.4676 29.7751 16.5568 29.8645 16.6649 29.927C16.773 29.9895 16.8962 30.023 17.0219 30.024C17.1482 30.0228 17.2719 29.9882 17.3798 29.924L17.428 29.896C17.5925 29.798 17.7113 29.6413 17.759 29.4591C17.8067 29.277 17.7796 29.0839 17.6834 28.921C17.6367 28.8416 17.5743 28.7721 17.4998 28.7164C17.4253 28.6608 17.3402 28.6202 17.2494 28.5969C17.1586 28.5736 17.064 28.5682 16.9711 28.5809C16.8781 28.5936 16.7887 28.6242 16.708 28.671Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.0648 15.4816C9.85117 15.2668 9.59548 15.096 9.31288 14.9794C9.03028 14.8627 8.72653 14.8026 8.41965 14.8026C8.11277 14.8026 7.80901 14.8627 7.52641 14.9794C7.24381 15.096 6.98812 15.2668 6.77452 15.4816C6.33791 15.9206 6.09375 16.5081 6.09375 17.1196C6.09375 17.7311 6.33791 18.3186 6.77452 18.7576L11.0002 22.9656C11.2139 23.1804 11.4697 23.3511 11.7524 23.4677C12.0351 23.5842 12.3389 23.6443 12.6458 23.6443C12.9527 23.6443 13.2565 23.5842 13.5392 23.4677C13.8219 23.3511 14.0777 23.1804 14.2914 22.9656L23.112 14.1826C23.5486 13.7436 23.7927 13.1562 23.7927 12.5446C23.7927 11.9331 23.5486 11.3457 23.112 10.9066C22.8983 10.6918 22.6425 10.521 22.3598 10.4043C22.0771 10.2876 21.7733 10.2275 21.4663 10.2275C21.1594 10.2275 20.8555 10.2876 20.5728 10.4043C20.2901 10.521 20.0344 10.6918 19.8207 10.9066L12.6412 18.0506L10.0648 15.4816ZM20.834 11.9136C20.9162 11.8308 21.0146 11.7649 21.1234 11.7199C21.2322 11.675 21.3492 11.6518 21.4673 11.6518C21.5855 11.6518 21.7025 11.675 21.8113 11.7199C21.9201 11.7649 22.0185 11.8308 22.1007 11.9136C22.2689 12.0825 22.363 12.3087 22.363 12.5441C22.363 12.7796 22.2689 13.0057 22.1007 13.1746L13.2802 21.9576C13.1979 22.0402 13.0994 22.1059 12.9906 22.1507C12.8818 22.1955 12.7649 22.2186 12.6468 22.2186C12.5287 22.2186 12.4118 22.1955 12.303 22.1507C12.1943 22.1059 12.0958 22.0402 12.0135 21.9576L7.78683 17.7506C7.61901 17.5813 7.5252 17.3551 7.5252 17.1196C7.5252 16.8842 7.61901 16.658 7.78683 16.4886C7.86922 16.406 7.96776 16.3403 8.07663 16.2955C8.1855 16.2507 8.30249 16.2276 8.42067 16.2276C8.53886 16.2276 8.65585 16.2507 8.76472 16.2955C8.87358 16.3403 8.97213 16.406 9.05452 16.4886L12.1407 19.5616C12.2064 19.6278 12.285 19.6804 12.372 19.7163C12.4589 19.7522 12.5524 19.7707 12.6468 19.7707C12.7413 19.7707 12.8347 19.7522 12.9217 19.7163C13.0086 19.6804 13.0873 19.6278 13.153 19.5616L20.834 11.9136Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
                <div className="amenities__content">
                  <h3 className="amenities__title">{t.charac7}</h3>
                  <p className="amenities__desc">{t.characDesc7}</p>
                </div>
              </div>
              <div className="amenities__box">
                <div className="amenities__icone text-right">
                  <span>
                    <svg
                      width={30}
                      height={34}
                      viewBox="0 0 30 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M28.9241 5.849C24.1438 4.52979 19.5648 2.59614 15.3077 0.098999C15.1979 0.0342357 15.0719 0 14.9436 0C14.8152 0 14.6893 0.0342357 14.5795 0.098999C10.3309 2.61282 5.74914 4.54746 0.962046 5.849C0.809441 5.89179 0.675396 5.98212 0.580388 6.10617C0.48538 6.23022 0.434632 6.38119 0.435891 6.53601V13.816C0.39353 16.6537 0.937333 19.4707 2.03491 22.0993C3.13249 24.7278 4.76139 27.1141 6.82461 29.116C9.96204 32.176 13.6164 33.869 14.9436 33.869C16.2708 33.869 19.9251 32.176 23.0626 29.116C25.1254 27.1139 26.754 24.7275 27.8514 22.099C28.9488 19.4705 29.4925 16.6536 29.4502 13.816V6.53601C29.4515 6.38119 29.4008 6.23022 29.3058 6.10617C29.2107 5.98212 29.0767 5.89179 28.9241 5.849ZM28.0195 13.816C28.0603 16.4651 27.5537 19.0952 26.5299 21.5493C25.5061 24.0035 23.9862 26.2317 22.0605 28.101C18.9754 31.11 15.6769 32.447 14.9436 32.447C14.2102 32.447 10.9118 31.11 7.82564 28.101C5.90036 26.2315 4.38068 24.0032 3.35711 21.5491C2.33353 19.0949 1.827 16.465 1.86769 13.816V7.077C6.45127 5.7833 10.8458 3.92178 14.9436 1.53799C19.0499 3.90571 23.4422 5.76632 28.0195 7.077V13.816Z"
                        fill="currentColor"
                      />
                      <path
                        d="M8.60038 6.99693C8.56698 6.91137 8.51655 6.83309 8.452 6.76661C8.38744 6.70014 8.31003 6.64678 8.22423 6.6096C8.13843 6.57242 8.04593 6.55218 7.95207 6.55C7.8582 6.54782 7.76482 6.56378 7.67731 6.59694C6.39833 7.09694 5.08654 7.55992 3.77987 7.96892C3.6333 8.01604 3.50581 8.10724 3.4157 8.22941C3.32559 8.35158 3.2775 8.49846 3.27833 8.64894V11.4009C3.27833 11.5866 3.35397 11.7646 3.48861 11.8959C3.62325 12.0272 3.80587 12.1009 3.99628 12.1009C4.18669 12.1009 4.36931 12.0272 4.50395 11.8959C4.63859 11.7646 4.71423 11.5866 4.71423 11.4009V9.17091C5.88859 8.79291 7.06192 8.37093 8.20859 7.92393C8.38518 7.84998 8.52507 7.71154 8.5983 7.53828C8.67153 7.36502 8.67227 7.17072 8.60038 6.99693Z"
                        fill="currentColor"
                      />
                      <path
                        d="M9.9588 7.13242C10.0578 7.13256 10.1557 7.1121 10.246 7.07243H10.2593C10.4341 6.99388 10.5703 6.85167 10.6388 6.67631C10.7072 6.50094 10.7025 6.30641 10.6255 6.13444C10.5888 6.04934 10.5352 5.97223 10.4676 5.9076C10.4001 5.84297 10.32 5.79212 10.232 5.75803C10.144 5.72395 10.05 5.70733 9.95535 5.70911C9.86071 5.7109 9.7674 5.73107 9.68085 5.76844L9.66957 5.77345C9.49468 5.85161 9.35824 5.99355 9.28958 6.16877C9.22091 6.34399 9.22551 6.53848 9.30239 6.71043C9.35785 6.83539 9.44949 6.94187 9.56608 7.01682C9.68267 7.09178 9.81915 7.13195 9.9588 7.13242Z"
                        fill="currentColor"
                      />
                      <path
                        d="M23.4697 22.9712C23.3912 22.9202 23.303 22.8849 23.2103 22.8677C23.1176 22.8504 23.0222 22.8515 22.93 22.8708C22.8377 22.8901 22.7503 22.9272 22.673 22.98C22.5957 23.0328 22.5301 23.1003 22.48 23.1782C21.878 24.0917 21.1892 24.9478 20.4225 25.7352C19.7865 26.3905 19.1008 26.9982 18.3712 27.5532C18.2193 27.6697 18.12 27.8395 18.0948 28.0264C18.0695 28.2132 18.1201 28.4023 18.2359 28.5532C18.2917 28.6276 18.3622 28.6902 18.4433 28.7374C18.5245 28.7846 18.6145 28.8154 18.7081 28.828C18.8016 28.8406 18.8969 28.8347 18.9881 28.8107C19.0793 28.7867 19.1646 28.745 19.2389 28.6882C20.0262 28.0893 20.7664 27.4338 21.4533 26.7272C22.2828 25.8745 23.028 24.9474 23.6789 23.9582C23.7832 23.7997 23.8197 23.6076 23.7806 23.4232C23.7415 23.2387 23.6299 23.0765 23.4697 22.9712Z"
                        fill="currentColor"
                      />
                      <path
                        d="M16.708 28.671L16.667 28.695C16.5021 28.7922 16.3827 28.9482 16.334 29.1299C16.2854 29.3116 16.3114 29.5047 16.4065 29.668C16.4676 29.7751 16.5568 29.8645 16.6649 29.927C16.773 29.9895 16.8962 30.023 17.0219 30.024C17.1482 30.0228 17.2719 29.9882 17.3798 29.924L17.428 29.896C17.5925 29.798 17.7113 29.6413 17.759 29.4591C17.8067 29.277 17.7796 29.0839 17.6834 28.921C17.6367 28.8416 17.5743 28.7721 17.4998 28.7164C17.4253 28.6608 17.3402 28.6202 17.2494 28.5969C17.1586 28.5736 17.064 28.5682 16.9711 28.5809C16.8781 28.5936 16.7887 28.6242 16.708 28.671Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.0648 15.4816C9.85117 15.2668 9.59548 15.096 9.31288 14.9794C9.03028 14.8627 8.72653 14.8026 8.41965 14.8026C8.11277 14.8026 7.80901 14.8627 7.52641 14.9794C7.24381 15.096 6.98812 15.2668 6.77452 15.4816C6.33791 15.9206 6.09375 16.5081 6.09375 17.1196C6.09375 17.7311 6.33791 18.3186 6.77452 18.7576L11.0002 22.9656C11.2139 23.1804 11.4697 23.3511 11.7524 23.4677C12.0351 23.5842 12.3389 23.6443 12.6458 23.6443C12.9527 23.6443 13.2565 23.5842 13.5392 23.4677C13.8219 23.3511 14.0777 23.1804 14.2914 22.9656L23.112 14.1826C23.5486 13.7436 23.7927 13.1562 23.7927 12.5446C23.7927 11.9331 23.5486 11.3457 23.112 10.9066C22.8983 10.6918 22.6425 10.521 22.3598 10.4043C22.0771 10.2876 21.7733 10.2275 21.4663 10.2275C21.1594 10.2275 20.8555 10.2876 20.5728 10.4043C20.2901 10.521 20.0344 10.6918 19.8207 10.9066L12.6412 18.0506L10.0648 15.4816ZM20.834 11.9136C20.9162 11.8308 21.0146 11.7649 21.1234 11.7199C21.2322 11.675 21.3492 11.6518 21.4673 11.6518C21.5855 11.6518 21.7025 11.675 21.8113 11.7199C21.9201 11.7649 22.0185 11.8308 22.1007 11.9136C22.2689 12.0825 22.363 12.3087 22.363 12.5441C22.363 12.7796 22.2689 13.0057 22.1007 13.1746L13.2802 21.9576C13.1979 22.0402 13.0994 22.1059 12.9906 22.1507C12.8818 22.1955 12.7649 22.2186 12.6468 22.2186C12.5287 22.2186 12.4118 22.1955 12.303 22.1507C12.1943 22.1059 12.0958 22.0402 12.0135 21.9576L7.78683 17.7506C7.61901 17.5813 7.5252 17.3551 7.5252 17.1196C7.5252 16.8842 7.61901 16.658 7.78683 16.4886C7.86922 16.406 7.96776 16.3403 8.07663 16.2955C8.1855 16.2507 8.30249 16.2276 8.42067 16.2276C8.53886 16.2276 8.65585 16.2507 8.76472 16.2955C8.87358 16.3403 8.97213 16.406 9.05452 16.4886L12.1407 19.5616C12.2064 19.6278 12.285 19.6804 12.372 19.7163C12.4589 19.7522 12.5524 19.7707 12.6468 19.7707C12.7413 19.7707 12.8347 19.7522 12.9217 19.7163C13.0086 19.6804 13.0873 19.6278 13.153 19.5616L20.834 11.9136Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </div>
                <div className="amenities__content">
                  <h3 className="amenities__title">{t.charac8}</h3>
                  <p className="amenities__desc">{t.characDesc8}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default SeoAbout5;
