const translations = {
    "az": {
        "home": "Ana Səhifə",
        "product": "Məhsullar",
        "about": "Haqqımızda",
        "contact": "Bizimlə Əlaqə",
        "kunstoff": "Scheffer plastİk qapı-pəncərə sİstemlərİ",
        "kunstoffTitle": "Scheffer plastik qapı-pəncərə sistemləri, müasir tikinti tələblərinə uyğun, enerji səmərəli və estetik həllər təqdim edir.",
        "kunstoffDesc": "Scheffer plastik qapı-pəncərə sistemləri, 30 ildən artıqdır ki, fəaliyyət göstərir. Bu müddət ərzində sistem, müasir tikinti sahəsində öz keyfiyyəti və dayanıqlılığı ilə tanınır. Həmçinin, inkişaf və yeniliklər sayəsində müştəri tələblərinə cavab verməyə davam edir.",
        "readMore": "Daha ətraflı",
        "serie": "Seriyası",
        "desc": "TƏSVİR",
        "charac": "XÜSUSİYYƏTLƏRİ",
        "address": "Ünvan",
        "addressDesc": "Saray qəsəbəsi, General Məhəmməd Əsədov 90",
        "phone": "Telefon",
        "star": "5 ulduz",
        "charac1": "Materialın Keyfiyyəti",
        "charac2": "Dizayn və Estetika",
        "year": "İldən artıq təcrübə",
        "charac3": "Enerji Səmərəliliyi",
        "charac4": "Quraşdırma və Texniki Xüsusiyyətlər",
        "charac5": "Təhlükəsizlik",
        "charac6": "Davamlılıq və Baxım",
        "charac7": "İstifadə Sahələri",
        "charac8": "Ekoloji Təsir",
        "kunstoffDesc2": "Scheffer PVC Türkiyədə yüksək keyfiyyətli premium pəncərə və qapı sistemləri istehsal edir. Şirkət müasir texnologiya və innovativ dizaynlarla müştərilərinə uzun ömürlü və enerji səmərəli həllər təqdim edir. Şirkətin əsas ofisi Beylikdüzü Mermercilər Sanayi Sitesi, 2. Cadde No:10, 34900 Beylikdüzü, İstanbul, Türkiyədə yerləşir.",
        "aboutHome": "Eviniz, keyfiyyətimizlə  tanış olsun!",
        "characDesc1": "Yüksək Keyfiyyətli PVC: Su və hava keçirməz, rəng sabitliyi ilə uzun ömürlü.",
        "characDesc2": "İqlimə Uyğunluq: Hər hava şəraitində səmərəli istilik saxlama.",
        "characDesc3": "Rəng Seçimləri: Müxtəlif rəng və tekstura variantları Fərqli Modellər: Müasir və klassik dizayn seçimləri.",
        "characDesc4": "Yüksək Performans: Səs (60 dB-ə qədər) və istilik izolyasiyası (U-dəyəri 0.7 W/m²K-ə qədər)",
        "characDesc5": "Uzun Ömür: 30 ildən çox istismar müddəti.",
        "characDesc6": "Müasir Kilid Sistemləri: Yüksək təhlükəsizlik təminatı.",
        "characDesc7": "Məişət Binaları, Ticarət Obyektləri, Sənaye Obyektləri",
        "characDesc8": "Təkrar Emal: PVC materiallarının ekoloji dostluğu.",
        "copyright":"Bütün hüquqlar qorunur.",
        "homeslider":"Pəncərə və qapı sistemləri",
        "homeslider1":"Scheffer plastik qapı-pəncərə sistemləri, müasir pəncərə sistemləri istehsalında ixtisaslaşmış bir şirkətdir.",
        "order":"Sifariş",
        "email":"E-poçt",
        "sliderTitle":"Evinizə öz ruhunuzu qatın!",
        "pages":"Səhifələr",
        "blog":"Bloq",
        "faq":"Tez-Tez verilən suallar",
        "contactTitle":"Baş ofis-Bakı İzmir küçəsi 6 Həyat Recens ",
        "contactTitle2":"Sumqayıt, S.Vurğun küçəsi 112, 14-cü məhəllə, Sumqayit",
        "catalogue":"Kataloq",
        "share":"Paylaş",
        "sliderTitle1":"Dİzaynınızı seçİn, bİz gerçəkləşdİrək!",
        "windowSetting":"Pəncərə Parametrləri",
        "color":"Rənglər",
        "packet":"Şüşə paket",
        "width":"Eni,mm",
        "height":"Hündürlüyü,mm",
        "name":"Ad",
        "lastName":"Soyad",
        "note":"Qeyd",
        "option":"Seçilməyib",
        "option1":"Tək qat şüşə paketi",
        "option2":"İki qat şüşə paketi",
        "option3":"Üç qat şüşə paketi",
        "option4":"Dekorativ şəbəkələr",
        "option5":"Enerji-qənaət şüşə paketi",
        "option6":"Rəngli şüşələr",
        "lastOrder":"Sifariş et",
        "success":"Sifariş göndərildi",
        "invalid":"500 - 2700 arasında bir dəyər daxil edin",
        "invalid1":"500 - 2000 arasında bir dəyər daxil edin",
        "otherBlog":"Digər Bloqlar",
        "sliderTitle2":"Dünyaya keyfİyyətlİ pəncərələr İlə baxın",
        "nameErr":"Ad boş buraxıla bilməz",
        "lastNameErr":"Soyad boş buraxıla bilməz",
        "phoneErr":"Telefon boş buraxıla bilməz",
        "noteErr":"Qeyd boş buraxıla bilməz",
        "seoAbout":"Plastik qapı pəncərə",
        "whyus":"Niyə Biz ?",
        "seoAbout1":"Sərfəli plastik qapı pəncərə",
        "seoAbout2":"Sərfəli plastik qapı",
        "seoAbout3":"Sərfəli plastik pəncərə",
        "seoAbout4":"Ucuz plastik qapı pəncərə",
        "seoAbout5":"Sərfəli plastik qapı pəncərə sifarişi"

    },
    "tr": {
        "home": "Anasayfa",
        "product": "Ürünler",
        "about": "Hakkımızda",
        "contact": "İletişim",
        "kunstoff": "Scheffer plastİk kapı-pencere sİstemlerİ",
        "kunstoffTitle": "Scheffer plastik kapı-pencere sistemleri, modern inşaat gereksinimlerine uygun, enerji verimli ve estetik çözümler sunar.",
        "kunstoffDesc": "Scheffer plastik kapı-pencere sİstemleri, 30 yıldan fazladır faaliyettedir. Bu süre zarfında sistem, modern inşaat alanında kalitesi ve dayanıklılığı ile tanınmaktadır. Ayrıca, gelişim ve yeniliklerle müşteri taleplerine cevap vermeye devam etmektedir.",
        "readMore": "Daha fazla",
        "serie": "Serisi",
        "desc": "AÇIKLAMA",
        "charac": "ÖZELLİKLERİ",
        "address": "Adres",
        "addressDesc": "Saray Mahallesi, General Mahmud Esedov 90",
        "phone": "Telefon",
        "star": "5 yıldız",
        "charac1": "Malzeme Kalitesi",
        "charac2": "Tasarım ve Estetik",
        "year": "Yıldan fazla deneyim",
        "charac3": "Enerji Verimliliği",
        "charac4": "Montaj ve Teknik Özellikler",
        "charac5": "Güvenlik",
        "charac6": "Dayanıklılık ve Bakım",
        "charac7": "Kullanım Alanları",
        "charac8": "Ekolojik Etki",
        "kunstoffDesc2": "Scheffer PVC Türkiye'de yüksek kaliteli premium pencere ve kapı sistemleri üretmektedir. Şirket, modern teknoloji ve yenilikçi tasarımlarla müşterilerine uzun ömürlü ve enerji verimli çözümler sunmaktadır. Şirketin merkez ofisi Beylikdüzü Mermerciler Sanayi Sitesi, 2. Cadde No:10, 34900 Beylikdüzü, İstanbul, Türkiye'de yer almaktadır.",
        "aboutHome": "Eviniz, Kalitemizle Tanışsın!",
        "characDesc1": "Yüksek Kaliteli PVC: Su ve hava geçirmez, renk stabilitesi ile uzun ömürlü.",
        "characDesc2": "İklime Uygunluk: Her hava koşulunda verimli ısı tutma.",
        "characDesc3": "Renk Seçenekleri: Farklı renk ve doku seçenekleri Farklı Modeller: Modern ve klasik tasarım seçenekleri.",
        "characDesc4": "Yüksek Performans: Ses (60 dB'ye kadar) ve ısı yalıtımı (U değeri 0.7 W/m²K'ye kadar)",
        "characDesc5": "Uzun Ömür: 30 yıldan fazla kullanım süresi.",
        "characDesc6": "Modern Kilit Sistemleri: Yüksek güvenlik garantisi.",
        "characDesc7": "Konut Binaları, Ticari Alanlar, Endüstriyel Tesisler",
        "characDesc8": "Geri Dönüşüm: PVC malzemelerin ekolojik dostluğu.",
        "copyright":"Tüm haklar saklıdır.",
        "homeslider": "Pencere ve kapı sistemleri",
        "homeslider1": "Pencere ve kapı sistemleri, modern pencere sistemleri üretiminde uzmanlaşmış bir şirkettir.",
        "order":"Sipariş",
        "email":"E-posta",
        "sliderTitle":"Evinize kendi ruhunuzu katın!",
        "pages":"Sayfalar",
        "blog":"Blog",
        "faq":"Sıkça Sorulan Sorular",
        "contactTitle":"Ana ofis - Bakü, İzmir Caddesi, 6, 'Hayat Recens'",
        "contactTitle2":'Sumgayıt, S. Vurğun Caddesi 112, 14. mahalle, Sumgayıt',
        "catalogue":"Katalog",
        "share":"Paylaş",
        "sliderTitle1":"Tasarımınızı seçİn, bİz gerçekleştİrelİm!",
        "windowSetting":"Pencere Ayarları",
        "color":"Renkler",
        "packet": "Cam paket",
    "width": "Genişlik, mm",
    "height": "Yükseklik, mm",
    "name": "Ad",
    "lastName": "Soyad",
    "note": "Not",
    "option": "Seçilmedi",
    "option1": "Tek cam paketi",
    "option2": "Çift cam paketi",
    "option3": "Üçlü cam paketi",
    "option4": "Dekoratif ağlar",
    "option5": "Enerji tasarruflu cam paketi",
    "option6": "Renkli camlar",
    "lastOrder":"Sipariş et",
    "success":"Sipariş gönderildi",
    "invalid":"500 - 2700 arasında bir değer girin",
    "invalid1":"500 - 2000 arasında bir değer girin",
    "otherBlog":"Diğer Bloglar",
    "sliderTitle2":"Dünyaya kalİtelİ pencerelerle bakın.",
    "nameErr": "Ad boş bırakılamaz",
    "lastNameErr": "Soyad boş bırakılamaz",
    "phoneErr": "Telefon boş bırakılamaz",
    "noteErr": "Not boş bırakılamaz",
    "seoAbout":"Plastik kapı pencere",
    "whyus":"NEden Biz ?",
    "seoAbout1":"Uygun fiyatlı plastik kapı ve pencere",
    "seoAbout2":"Uygun fiyatlı plastik kapı",
    "seoAbout3":"Uygun fiyatlı plastik pencere",
    "seoAbout4":"Ucuz plastik kapı ve pencere",
    "seoAbout5":"Uygun fiyatlı plastik kapı ve pencere siparişi"
    },
    "en": {
        "home": "Home",
        "product": "Products",
        "about": "About Us",
        "contact": "Contact Us",
        "kunstoff": "Scheffer plastic door and window systems",
        "kunstoffTitle": "Scheffer plastic door and window systems provide energy-efficient and aesthetic solutions that meet modern construction requirements.",
        "kunstoffDesc": "The Scheffer plastic door and window systems has been in operation for over 30 years. During this time, the system has been recognized for its quality and durability in the modern construction field. It continues to meet customer demands through development and innovations.",
        "readMore": "Read More",
        "serie": "Series",
        "desc": "DESCRIPTION",
        "charac": "FEATURES",
        "address": "Address",
        "addressDesc": "Saray Settlement, General Mahammad Asadov 90",
        "phone": "Phone",
        "star": "5 stars",
        "charac1": "Material Quality",
        "charac2": "Design and Aesthetics",
        "year": "Over a Year of Experience",
        "charac3": "Energy Efficiency",
        "charac4": "Installation and Technical Specifications",
        "charac5": "Safety",
        "charac6": "Durability and Maintenance",
        "charac7": "Areas of Use",
        "charac8": "Ecological Impact",
        "kunstoffDesc2": "Scheffer PVC manufactures high-quality premium window and door systems in Turkey. The company offers its customers long-lasting and energy-efficient solutions using modern technology and innovative designs. The company's headquarters is located at Beylikdüzü Mermerciler Sanayi Sitesi, 2. Cadde No:10, 34900 Beylikdüzü, Istanbul, Turkey.",
        "aboutHome": "Let Your Home Experience Our Quality!",
        "characDesc1": "High-Quality PVC: Waterproof and airtight, durable with color stability.",
        "characDesc2": "Climate Compatibility: Efficient heat retention in all weather conditions.",
        "characDesc3": "Color Options: Various colors and textures Different Models: Modern and classic design options.",
        "characDesc4": "High Performance: Sound (up to 60 dB) and thermal insulation (U-value up to 0.7 W/m²K)",
        "characDesc5": "Long Lifespan: Over 30 years of service life.",
        "characDesc6": "Modern Lock Systems: High security assurance.",
        "characDesc7": "Residential Buildings, Commercial Facilities, Industrial Sites",
        "characDesc8": "Recycling: Ecological friendliness of PVC materials.",
        "copyright":"All rights reserved.",
        "homeslider": "Window and door systems",
        "homeslider1": "Window and door systems is a company specialized in the production of modern window systems.",
        "order":"Order",
        "email":"Email",
        "sliderTitle":"Add your own spirit to your home!",
        "pages":"Pages",
        "blog":"Blog",
        "faq":"FAQ",
        "contactTitle":'Head office - Baku, Izmir Street, 6, "Hayat Recens"',
        "contactTitle2":'Sumgayit, S. Vurgun Street 112, 14th neighborhood, Sumgayit',
        "catalogue":"Catalogue",
        "share":"Share",
        "sliderTitle1":"Choose your design, and we will bring it to life!",
        "windowSetting":"Window Settings",
        "color":"Colors",
        "packet": "Glass package",
    "width": "Width, mm",
    "height": "Height, mm",
    "name": "Name",
    "lastName": "Last Name",
    "note": "Note",
    "option": "Not selected",
    "option1": "Single glazing glass package",
    "option2": "Double glazing glass package",
    "option3": "Triple glazing glass package",
    "option4": "Decorative grids",
    "option5": "Energy-saving glass package",
    "option6": "Colored glass",
    "lastOrder":"Place an order",
    "invalid":"Enter a value between 500 and 2700",
    "invalid1":"Enter a value between 500 and 2000",
    "success":"Order has been sent",
    "otherBlog":"Other Blogs",
    "sliderTitle2":"Look at the world through quality windows.",
    "nameErr": "Name cannot be empty",
    "lastNameErr": "Last name cannot be empty",
    "phoneErr": "Phone number cannot be empty",
    "noteErr": "Note cannot be empty",
    "seoAbout":"Plastic door window",
    "whyus":"Why Us ?",
    "seoAbout1":"Affordable plastic doors and windows",
    "seoAbout2":"Affordable plastic door",
    "seoAbout3":"Affordable plastic window",
    "seoAbout4":"Cheap plastic doors and windows",
    "seoAbout5":"Order of affordable plastic doors and windows"
    },
    "ru": {
        "home": "Главная страница",
        "product": "Продукты",
        "about": "О нас",
        "contact": "Связаться с нами",
        "kunstoff": "Пластиковые системы дверей и окон Scheffer",
        "kunstoffTitle": "Системы Kunststoff Scheffer предлагают энергоэффективные и эстетические решения, соответствующие современным строительным требованиям.",
        "kunstoffDesc": "Система Kunststoff Scheffer работает более 30 лет. За это время система зарекомендовала себя качеством и долговечностью в современном строительстве. Также благодаря развитию и инновациям она продолжает удовлетворять потребности клиентов.",
        "readMore": "Читать далее",
        "serie": "Серия",
        "desc": "ОПИСАНИЕ",
        "charac": "ХАРАКТЕРИСТИКИ",
        "address": "Адрес",
        "addressDesc": "Поселок Сарай, Генерал Махамед Асадов 90",
        "phone": "Телефон",
        "star": "5 звезд",
        "charac1": "Качество материала",
        "charac2": "Дизайн и эстетика",
        "year": "Более года опыта",
        "charac3": "Энергоэффективность",
        "charac4": "Монтаж и технические характеристики",
        "charac5": "Безопасность",
        "charac6": "Долговечность и обслуживание",
        "charac7": "Области применения",
        "charac8": "Экологическое воздействие",
        "kunstoffDesc2": "Scheffer PVC производит высококачественные премиум оконные и дверные системы в Турции. Компания предлагает своим клиентам долговечные и энергоэффективные решения с использованием современных технологий и инновационного дизайна. Главный офис компании находится по адресу: Beylikdüzü Mermerciler Sanayi Sitesi, 2. Cadde No:10, 34900 Beylikdüzü, İstanbul, Турция",
        "aboutHome": "Позвольте вашему дому познакомиться с нашим качеством!",
        "characDesc1": "Высококачественный ПВХ: Водонепроницаемый и воздухонепроницаемый, долговечный с цветовой стабильностью.",
        "characDesc2": "Климатическая совместимость: Эффективное удержание тепла при любых погодных условиях.",
        "characDesc3": "Выбор цветов: Различные варианты цветов и текстур Разные модели: Современные и классические дизайнерские варианты.",
        "characDesc4": "Высокая производительность: Шум (до 60 дБ) и теплоизоляция (коэффициент U до 0,7 Вт/м²К)",
        "characDesc5": "Долгий срок службы: Более 30 лет эксплуатации.",
        "characDesc6": "Современные системы замков: Высокая степень безопасности.",
        "characDesc7": "Жилые здания, коммерческие объекты, промышленные объекты",
        "characDesc8": "Переработка: Экологическая безопасность материалов ПВХ.",
        "copyright":"Все права защищены.",
        "homeslider": "Системы окон и дверей",
        "homeslider1": "Kunststoff Schäfer System — компания, специализирующаяся на производстве современных оконных систем.",
        "order":"Заказ",
        "email":"Электронная почта",
        "sliderTitle":"Добавьте свой дух в ваш дом!",
        "pages":"Страницы ",
        "blog":"Блог",
        "faq":"ЧЗВ",
        "contactTitle":'Главный офис - Баку, улица Измир, 6, "Хаят Ресенс"',
        "contactTitle2":'Сумгайыт, улица С. Вургуна 112, 14-й микрорайон, Сумгайыт',
        "catalogue":"Каталог",
        "share":"Поделиться",
        "sliderTitle1":"Выберите ваш дизайн, а мы его реализуем!",
        "windowSetting":"Параметры окна",
        "color":"Цвета",
        "packet": "Стеклянная упаковка",
        "width": "Ширина, мм",
        "height": "Высота, мм",
        "name": "Имя",
        "lastName": "Фамилия",
        "note": "Заметка",
        "option": "Не выбрано",
        "option1": "Однокомпонентная стеклянная упаковка",
        "option2": "Двукомпонентная стеклянная упаковка",
        "option3": "Трехкомпонентная стеклянная упаковка",
        "option4": "Декоративные сетки",
        "option5": "Энергосберегающее стеклянное упаковка",
        "option6": "Цветное стекло",
        "lastOrder":"Заказать",
        "invalid":"Введите значение от 500 до 2700",
        "invalid1":"Введите значение от 500 до 2000",
        "success":"Заказ отправлен",
        "otherBlog":"Другие блоги",
        "sliderTitle2":"Смотрите на мир через качественные окна.",
        "nameErr": "Имя не может быть пустым",
        "lastNameErr": "Фамилия не может быть пустой",
        "phoneErr": "Телефон не может быть пустым",
        "noteErr": "Заметка не может быть пустой",
        "seoAbout":"Пластиковая дверь окно",
        "whyus":"Почему мы ?",
        "seoAbout1":"Bыгодные пластиковые двери и окна",
        "seoAbout2":"Bыгодная пластиковая дверь",
        "seoAbout3":"Bыгодное пластиковое окно",
        "seoAbout4":"Дешевые пластиковые двери и окна",
        "seoAbout5":"Заказ выгодных пластиковых дверей и окон"
    },
    "de": {
        "home": "Startseite",
        "product": "Produkte",
        "about": "Über uns",
        "contact": "Kontakt",
        "kunstoff": "Scheffer Kunststofftür- und -fenstersysteme",
        "kunstoffTitle": "Kunststoff Scheffer Systeme bieten energieeffiziente und ästhetische Lösungen, die den modernen Bauanforderungen entsprechen.",
        "kunstoffDesc": "Das Kunststoff Scheffer System ist seit über 30 Jahren im Einsatz. In dieser Zeit hat sich das System in der modernen Bauindustrie durch Qualität und Langlebigkeit einen Namen gemacht. Außerdem erfüllt es weiterhin die Anforderungen der Kunden durch Entwicklung und Innovationen.",
        "readMore": "Mehr erfahren",
        "serie": "Serie",
        "desc": "BESCHREIBUNG",
        "charac": "MERKMALE",
        "address": "Adresse",
        "addressDesc": "Saray-Siedlung, General Mahammad Asadov 90",
        "phone": "Telefon",
        "star": "5 Sterne",
        "charac1": "Materialqualität",
        "charac2": "Design und Ästhetik",
        "year": "Über ein Jahr Erfahrung",
        "charac3": "Energieeffizienz",
        "charac4": "Installation und technische Spezifikationen",
        "charac5": "Sicherheit",
        "charac6": "Haltbarkeit und Wartung",
        "charac7": "Anwendungsbereiche",
        "charac8": "Ökologische Auswirkungen",
        "kunstoffDesc2": "Scheffer PVC stellt hochwertige Premium-Fenster- und Türsysteme in der Türkei her. Das Unternehmen bietet seinen Kunden langlebige und energieeffiziente Lösungen mit modernster Technologie und innovativem Design. Der Hauptsitz des Unternehmens befindet sich in Beylikdüzü Mermerciler Sanayi Sitesi, 2. Cadde No:10, 34900 Beylikdüzü, Istanbul, Türkei.",
        "aboutHome": "Lassen Sie Ihr Zuhause unsere Qualität erleben!",
        "characDesc1": "Hochwertiges PVC: Wasser- und luftdicht, langlebig mit Farbkonstanz.",
        "characDesc2": "Klimakompatibilität: Effiziente Wärmespeicherung bei allen Wetterbedingungen.",
        "characDesc3": "Farbauswahl: Verschiedene Farb- und Texturoptionen Verschiedene Modelle: Moderne und klassische Designoptionen.",
        "characDesc4": "Hohe Leistung: Schall (bis zu 60 dB) und Wärmedämmung (U-Wert bis 0,7 W/m²K)",
        "characDesc5": "Lange Lebensdauer: Über 30 Jahre Nutzungsdauer.",
        "characDesc6": "Moderne Schließsysteme: Hohe Sicherheitsgarantie.",
        "characDesc7": "Wohngebäude, Gewerbeobjekte, Industrieanlagen",
        "characDesc8": "Recycling: Ökologische Verträglichkeit von PVC-Materialien.",
        "copyright":"Alle Rechte vorbehalten.",
        "homeslider": "Fenster- und Türsysteme",
        "homeslider1": "Kunststoff Schäfer System ist ein Unternehmen, das auf die Herstellung moderner Fenstersysteme spezialisiert ist.",
        "order":"Bestellung",
        "email":"E-Mail",
        "sliderTitle":"Fügen Sie Ihrem Zuhause Ihren eigenen Geist hinzu!",
        "pages":"Seiten",
        "blog":"Blog",
        "faq":"Häufig gestellte Fragen",
        "contactTitle":'Hauptbüro - Baku, Izmir Straße, 6, "Hayat Recens"',
        "contactTitle2":'Sumgayıt, S. Vurğun Straße 112, 14. Nachbarschaft, Sumgayıt',
        "catalogue":"Katalog",
        "share":"Teilen",
        "sliderTitle1":"Wählen Sie Ihr Design, und wir setzen es um!",
        "windowSetting":"Fenster-Einstellungen",
        "color":"Farben",
        "packet": "Glasverpackung",
    "width": "Breite, mm",
    "height": "Höhe, mm",
    "name": "Name",
    "lastName": "Nachname",
    "note": "Hinweis",
    "option": "Nicht ausgewählt",
    "option1": "Einfachverglasung",
    "option2": "Doppelverglasung",
    "option3": "Dreifachverglasung",
    "option4": "Dekorative Gitter",
    "option5": "Energiesparende Glasverpackung",
    "option6": "Buntes Glas",
    "lastOrder":"Bestellen ",
    "invalid":"Geben Sie einen Wert zwischen 500 und 2700 ein",
    "invalid1":"Geben Sie einen Wert zwischen 500 und 2000 ein",
    "success":"Bestellung wurde gesendet",
    "otherBlog":"Andere Blogs",
    "sliderTitle2":"Blicken Sie auf die Welt durch qualitativ hochwertige Fenster.",
    "nameErr": "Name darf nicht leer sein",
    "lastNameErr": "Nachname darf nicht leer sein",
    "phoneErr": "Telefonnummer darf nicht leer sein",
    "noteErr": "Notiz darf nicht leer sein",
    "seoAbout":"Kunststofftür Fenster",
    "whyus":"Warum wir ?",
    "seoAbout1":"Preiswerte Kunststofftüren und Fenster",
    "seoAbout2":"Preiswerte Kunststofftür",
    "seoAbout3":"Preiswertes Kunststofffenster",
    "seoAbout4":"Billige Kunststofftüren und Fenster",
    "seoAbout5":"Bestellung von preiswerten Kunststofftüren und Fenstern"
    }
}


export default translations;

